import React from "react";
import { TextField, Box } from "@mui/material";
import { WithHeader } from "../common/WithHeader";

const TextFields = (props) => {
  const {
    handleTextField,
    defaultValue,
    placeholder,
    helperText,
    disabled,
    error,
  } = props;

  return (
    <>
      <TextField
        disabled={disabled}
        placeholder={placeholder}
        multiline
        rows={2}
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-root": {
            borderRadius: 0, // set borderRadius to 0
          },
        }}
        onChange={(event) => {
          handleTextField(event); // Pass the new value to the handler
        }}
        value={defaultValue}
        variant="outlined"
        helperText={helperText}
        error={error}
      />
    </>
  );
};

export default WithHeader(TextFields, "title");
