import React, { useEffect, useState } from "react";
import ContentHeader from "../../common/ContentHeader";
import EventTable from "./EventTable";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { clearCurrentEvent } from "../../../store/actions/currentEvent";
import { clearCurrentRescource } from "../../../store/actions/currentArticle";
import { getEventsData } from "../../../store/actions/event";
import { filterTableRows } from "../../common/GeneralHelper";

const EventListing = () => {
  const { events } = useSelector((state) => state.events);
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (id === undefined) {
      dispatch(clearCurrentEvent());
      dispatch(clearCurrentRescource());
    }
    dispatch(getEventsData());
  }, [dispatch, id]);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleClearSearchQuery = (event) => {
    setSearchQuery("");
  };
  const filteredData = filterTableRows(events, searchQuery);

  return (
    <>
      <ContentHeader
        header="Events"
        route="/new-events"
        searchQuery={searchQuery}
        onSearchQueryChange={handleSearchQueryChange}
        onClearSearchQuery={handleClearSearchQuery}
        searchPlaceholder="Search by Title, Category"
        isNearBy={false}
        count={filteredData?.length ?? 0}
      />
      <EventTable data={filteredData} />
    </>
  );
};

export default EventListing;
