import React, { useEffect, useState } from "react";
import TickMapHeader from "../../component/TickMap/TickMapComponents/header";
import TickMapSubmission from "../../component/TickMap/TickMapComponents/TickMapSubmissions";
import { useFormik } from "formik";
import TickMapManagementSchema from "../../component/common/ValidationSchema/TaskMapManagementSchema";
import RTextArea from "../../component/formFields/RTextArea";
import {
  createTickMap,
  getTickMap,
  sendNotification,
  setCoverImage,
  updateFormData,
  updateSuccess,
} from "../../store/actions/tickMapManagement";
import { useDispatch, useSelector } from "react-redux";
import {
  localizedObject,
  validateUrl,
} from "../../component/common/GeneralHelper";
import { toast } from "react-toastify";
import { selectClasses } from "@mui/material";
import { URL_REGEX, YT_REGEX } from "../../constants/GeneralConstant";

function TickMapManagement() {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.tickMap.selectedLanguage);
  const taskMapData = useSelector((state) => state.tickMap.formData);
  const coverImage = useSelector((state) => state.tickMap.coverImage);
  const error = useSelector((state) => state.tickMap.error);
  const success = useSelector((state) => state.tickMap.success);

  const [notificationDialogOpen, setNotificationDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(getTickMap());
  }, []);

  useEffect(() => {
    dispatch(setCoverImage(taskMapData?.coverImage));
  }, [language]);

  useEffect(() => {
    formik.setFieldValue("content", taskMapData?.content?.[language]);
    formik.setFieldValue(
      "notificationContent",
      taskMapData?.notificationContent?.[language]
    );
    formik.setFieldValue("link", taskMapData?.link);
    formik.setFieldValue("coverImage", taskMapData?.coverImage);
  }, [taskMapData, language]);

  useEffect(() => {
    if (success) {
      toast.success("Successfully saved");
    }
    return () => {
      dispatch(updateSuccess(false));
    };
  }, [success]);

  const handleFieldChange = (fieldName, value) => {
    const updatedFormData = { ...taskMapData };

    if (fieldName === "notificationContent") {
      updatedFormData.notificationContent[language] = value;
    } else if (fieldName === "content") {
      updatedFormData.content[language] = value;
    } else {
      updatedFormData[fieldName] = value;
    }
    dispatch(updateFormData(updatedFormData)); // Dispatch updated data to Redux store
  };

  const handleSearchQueryChange = (event) => {};
  const handleClearSearchQuery = (event) => {};

  const handleSubmit = (values) => {
    const isUrl = validateUrl(URL_REGEX);

    if (values["link"] && !isUrl(values["link"])) {
      toast.error("Link is Invalid");
      return;
    }
    let payload = {
      link: values["link"],
      coverImage: values["coverImage"],
      notificationContent: taskMapData.notificationContent,
      content: taskMapData.content,
    };
    dispatch(createTickMap(payload)).then((res) => {
      setNotificationDialogOpen(true);
    });
  };

  const handleSendNotification = (data) => {
    dispatch(sendNotification(data))
      .then((res) => {
        toast.success("Notification Sent");
      })
      .catch((e) => {
        console.log("e", e);
      })
      .finally(() => {
        setNotificationDialogOpen(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      link: taskMapData?.link ?? "",
      coverImage: taskMapData?.coverImage ?? "",
      content: taskMapData?.content?.[language] ?? "",
      notificationContent: taskMapData?.notificationContent?.[language] ?? "",
    },
    validate: (values) => {
      // const linkRegex =
      //   /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[A-Za-z0-9_-]{11}(&.*)?$/;

      // if (values.link && !linkRegex.test(values.link)) {
      //   toast.error("Link is not valid");
      // }
      if (!values.content) {
        toast.error("Content is Required");
      }
      if (!values.coverImage) {
        toast.error("Cover Image is Required");
      }

      if (!values.link) {
        toast.error("Link is Required");
      }
    },
    // enableReinitialize: true,
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <TickMapHeader
          header="Tick Map Management"
          route="/tick-map-management"
          searchQuery={null}
          onSearchQueryChange={handleSearchQueryChange}
          onClearSearchQuery={handleClearSearchQuery}
          searchPlaceholder="Search by Title, Category"
          isNearBy={false}
          handleSendNotification={handleSendNotification}
          notificationDialogOpen={notificationDialogOpen}
          setNotificationDialogOpen={setNotificationDialogOpen}
        />

        <TickMapSubmission
          formik={formik}
          lang={language || "EN"}
          coverImage={coverImage}
          handleFieldChange={handleFieldChange}
        />
      </form>
    </>
  );
}

export default TickMapManagement;
