import React from "react";
import { TextField, Box, Button, Stack } from "@mui/material";

const Header = ({ formik, handleFieldChange }) => {
  return (
    <>
      <Stack >
        <Box
         sx={{
          display: "flex",
          mb: 1.5,
          pt: 0.75,
          pb: 2,
          borderBottom: '1px solid #E1E1E1',
          gap: 1,
        }}>
          <TextField
            id="firstname"
            name="firstname"
            size="small"
            className="firstname"
            required
            value={formik.values.firstname || ""}
            variant="outlined"
            onChange={(e) => handleFieldChange("firstname", e.target.value)}
            placeholder={`First Name`}
            fullWidth
          />
          <TextField
            id="lastname"
            name="lastname"
            size="small"
            className="lastname"
            required
            value={formik.values.lastname || ""}
            variant="outlined"
            onChange={(e) => handleFieldChange("lastname", e.target.value)}
            placeholder={`Last Name`}
            fullWidth
          />

      <Button
          variant="contained"
          size="small"
          type="submit"
          sx={{width: '150px'}}
          // onClick={() => navigate(route)}
        >
          Save
        </Button>
        </Box>


      </Stack>
    </>
  );
};

export default Header;
