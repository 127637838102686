import {
  createNearBy,
  editNearBy,
  getNearByList,
} from "../../../store/actions/nearBy";

import { validateNearBy } from "../../validations/ValidateNearby";

import { URL } from "../../../constants/URL";
import { getRequiredAspectRatio } from "../../gallery/gallery/Helper";
import UrlInputField from "../../formFields/UrlInputField.js";
import RTextArea from "./../../formFields/RTextArea";
import {
  setCoverImage,
  updateFormData,
} from "../../../store/actions/tickMapManagement.js";
import { useDispatch, useSelector } from "react-redux";
import CoverImage from "../../formFields/CoverImage/index.js";
import { Box } from "@mui/material";

const TickMapSubmission = ({ formik, lang, coverImage, handleFieldChange }) => {
  const recommendedSize =
    "Best results with a 16:9 image, like 640x360 or 960x540.";
  const TOLERANCE = 0;
  const dispatch = useDispatch();
  const taskMapData = useSelector((state) => state.tickMap.formData);

  const handleSetCoverImage = (file) => {
    const temp = { ...taskMapData };

    temp["coverImage"] = file;
    dispatch(setCoverImage(file));
    dispatch(updateFormData(temp));
  };

  return (
    <>
      <Box sx={{mb: 2}}>
        <RTextArea
          name={`content`}
          value={formik.values.content ?? ""}
          error={formik.touched.content && Boolean(formik.errors.content)}
          helperText={formik.touched.content && formik.errors.content}
          label="Content *"
          setHtmlImage={(filename) => {}}
          onChange={(e) => {
            formik.handleChange(e); // Update Formik's state
            handleFieldChange("content", e.target.value); // Update Redux
          }}
        />
      </Box>

      <Box sx={{mb: 2.5}}>
        <CoverImage
          setCoverImage={handleSetCoverImage}
          selectedCoverImage={coverImage}
          recommendedSize={recommendedSize}
          requiredAspectRatio={getRequiredAspectRatio()}
          TOLERANCE={TOLERANCE}
        />
      </Box>

      <Box sx={{mb: 2}}>
        <UrlInputField
          placeholder={"Enter the link to the tick map here..."}
          name={`link`}
          title={"Link *"}
          value={formik.values.link ?? ""}
          onChange={(e) => {
            formik.handleChange(e); // Update Formik's state
            handleFieldChange("link", e.target.value); // Update Redux
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.link && Boolean(formik.errors.link)}
        />
      </Box>
    </>
  );
};

export default TickMapSubmission;
