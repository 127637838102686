// src/redux/reducers/faqCategoriesReducer.js
import {
  ADD_FAQS_CATEGORY_SUCCESS,
  DELETE_FAQ_SUCCESS,
  EDIT_FAQ_CATEGORY_SUCCESS,
  FETCH_FAQS_CATEGORIES_FAILURE,
  FETCH_FAQS_CATEGORIES_REQUEST,
  FETCH_FAQS_CATEGORIES_SUCCESS,
  SET_FAQS_CATEGORIES_LANGUAGE,
  UPDATE_FAQS_CATEGORIES_FORM,
} from "../actions/type";

const initialState = {
  selectedLanguage: "EN",
  loading: false,
  categories: [],
  error: null,
};

const faqCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FAQS_CATEGORIES_LANGUAGE:
      return { ...state, selectedLanguage: action.payload };
    case UPDATE_FAQS_CATEGORIES_FORM:
      return { ...state, categories: action.payload };
    case FETCH_FAQS_CATEGORIES_REQUEST:
      return { ...state, loading: true };
    case FETCH_FAQS_CATEGORIES_SUCCESS:
      return { ...state, loading: false, categories: action.payload };
    case FETCH_FAQS_CATEGORIES_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ADD_FAQS_CATEGORY_SUCCESS:
      return { ...state, categories: [...state.categories, action.payload] };
    case EDIT_FAQ_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: state.categories.map((cat) =>
          categories.id === action.payload.id ? action.payload : cat
        ),
      };
    case DELETE_FAQ_SUCCESS:
      return {
        ...state,
        categories: state.categories.filter((cat) => cat.id !== action.payload),
      };
    default:
      return state;
  }
};

export default faqCategoriesReducer;
