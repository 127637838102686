import { TERM_AND_CONDITION_URL } from "./apiUrls";

export const PERMISSIONS = {
  USER: "User",
  CONTENT: 'Content',
  GAMIFICATION: 'Gamification',
  TICK_MAP: 'Tickmap',
  FAQS: 'FAQs',
  ARTICLES: 'Articles',
  VIDEOS: 'Videos',
  GALLERY: 'Gallery',
  SCORECARD: 'Scorecard',
  EVENTS: 'Events',
  NEAR_BY_HELP: 'NearByHelp',
  PRODUCTS: 'Products',
  REWARDS: 'Rewards',
  TRAINING: 'Training',
  KNOW_YOUR_TICKS: 'KYT',
  TERM_AND_CONDITION: 'TermAndCondition',
  PRIVACY_POLICY: 'PrivacyPolicy',
};
