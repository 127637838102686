import React from "react";
import { TextField } from "@mui/material";
import { WithHeader } from "../../../common/WithHeader";
// import { localizedAddressSelector } from '../../store/reducers/selector';
import { useDispatch, useSelector } from "react-redux";
import TextFields from "../../../common/TextFields";
import { setFullName } from "../../../../store/actions/nearByActions";
import { NEAR_BY_FIELDS } from "../../../../constants/GeneralConstant";
import { localizedNearByNameSelector } from "../../../../store/reducers/selector";

const FullName = () => {
  const dispatch = useDispatch();

  const name = useSelector(localizedNearByNameSelector);

  const HandleFullName = (event) => {
    const text = event.target.value;
    const trimmedText = text.trimStart(); // Remove only leading spaces
    dispatch(setFullName(trimmedText));
  };
  return (
    <>
      <TextFields
        placeholder={"Enter FullName of nearby here"}
        defaultValue={name || ""}
        name={"nearby"}
        handleTextField={HandleFullName}
        title={NEAR_BY_FIELDS.FULL_NAME}
      ></TextFields>
    </>
  );
};

export default FullName;
