export const URL = {
  LOGIN: "/login",
  RESOURCE: "/learning-resource",
  LEARNING_RESOURCE_LISTING: "/learning-resource/list",
  NEARBYHELP: "/nearbyhelp",
  CONFIRM_DELETE_REQUEST: "auth/delete-account-request",
  DELETE_ACCOUNT_REQUEST: "/auth/delete-account",
  TOGGLE_GATE_UNGATE: "/nearbyhelp/setAuthRequired",
  FAQS: "/faqs",
  FAQS_EDIT: "/faqs/:id/edit",
  FAQS_ADD: "/faqs/add",
  USERS: "/users",
  USER_ADD: "users/add",
  USER_EDIT: "/users/:id/edit",
};
