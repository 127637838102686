import { TextField, Box } from "@mui/material";
import React from "react";
import TextFields from "../../../../component/common/TextFields";
import NestedList from "./NestedList";
import { useSelector } from "react-redux";

const UserForm = ({ isEdit, formik, handleFieldChange }) => {
  const permissionsList = useSelector((state) => state.users.permissionsList);
  const { permissions } = useSelector((state) => state.users.formData);

  return (
    <>
    <Box sx={{ mb: 2 }}>
      <TextFields
        id=""
        name="email"
        size="small"
        className="email"
        required
        defaultValue={formik.values.email || ""}
        variant="outlined"
        handleTextField={(e) => handleFieldChange("email", e.target.value)}
        fullWidth
        title={"Email"}
        placeholder={`Email`}
      />
      </Box>

      <Box sx={{ mb: 2 }}>
      <NestedList
        isEdit={isEdit}
        title={"Permissions"}
        menuList={permissionsList}
        selectedItems={permissions}
      />
      </Box>
    </>
  );
};
export default UserForm;
