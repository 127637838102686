import React from "react";
import { Button, IconButton, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import FilteredCreationDate from "../filter/FilteredCreationDate";
import FilteredUpdateDate from "../filter/FilteredUpdateDate";

const FilterBoxModel = ({
  handleClose,
  filteredData,
  setFilteredData,
  setCreateDateError,
  setUpdateDateError,
  HandleClearAll,
  handleApply,
}) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx={{ textAlign: "center", flexGrow: 1 }}>
          Filter
        </Typography>
        <IconButton onClick={handleClose}>
          <ClearIcon color="action" fontSize="small" />
        </IconButton>
      </div>
      <FilteredCreationDate
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        setCreateDateError={setCreateDateError}
      />
      <FilteredUpdateDate
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        setUpdateDateError={setUpdateDateError}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center", // Align buttons in the center horizontally
          gap: "1em", // Add gap between buttons
          marginTop: "1em", // Add margin from the above content
        }}
      >
        <Button variant="contained" size="small" onClick={HandleClearAll}>
          Clear all
        </Button>
        <Button variant="contained" size="small" onClick={handleApply}>
          Apply
        </Button>
      </div>
    </div>
  );
};

export default FilterBoxModel;
