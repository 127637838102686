import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { WithHeader } from '../common/WithHeader';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  setEventEndDate,
  setEventStartDate,
} from '../../store/actions/currentEvent';
import { Stack, Box } from '@mui/material';
import {
  eventEndDateSelector,
  eventStartDateSelector,
} from '../../store/reducers/selector';

dayjs.extend(utc);
const EventDate = ({ setDateError }) => {
  const dispatch = useDispatch();
  const [startDateTime, setStartDateTime] = useState();
  const [endDateTime, setEndDateTime] = useState();
  const [error, setError] = useState('');
  const startEvent = useSelector(eventStartDateSelector);
  const endEvent = useSelector(eventEndDateSelector);
  const startdateObj = dayjs.utc(startEvent).local(); // Convert UTC to local timezone
  const enddateObj = dayjs.utc(endEvent).local();

  const handleStartDateChange = (date) => {
    const start = new Date(date).getTime();
    const isoDate = dayjs(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'); // Convert to UTC
    setStartDateTime(start);
    dispatch(setEventStartDate(isoDate));
    if (endDateTime === undefined) {
      const end = new Date(endEvent).getTime();
      validateDate(start, end);
    } else {
      validateDate(start, endDateTime);
    }
  };

  const handleEndDateChange = (date) => {
    const end = new Date(date).getTime();
    const isoDate = dayjs(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'); // Convert to UTC
    setEndDateTime(dayjs(end));
    validateDate(startDateTime, end);
    // if (!error) {
    dispatch(setEventEndDate(isoDate));
    // }
  };

  const validateDate = (start, end) => {
    if (start >= end) {
      setError('Invalid End Date');
      setDateError('Invalid');
    } else {
      setError('');
      setDateError('');
    }
  };
  return (
    <Box sx={{ p: 2 }}>
      <Stack spacing={2}>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DateTimePicker']}>
            <DateTimePicker
              label="Start Date and Time"
              value={startdateObj}
              disablePast
              onChange={handleStartDateChange}
            />
          </DemoContainer>
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DateTimePicker']}>
            <DateTimePicker
              label="End Date and Time"
              value={enddateObj}
              disablePast
              onChange={handleEndDateChange}
            />
          </DemoContainer>
        </LocalizationProvider>
        {error && <div style={{ color: 'red' }}>{error}</div>}
      </Stack>
    </Box>
  );
};
export default WithHeader(EventDate, 'Event Date & Time *');
