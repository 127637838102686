import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";

const NotificationDialog = ({ open, onClose, handleSendNotification }) => {
  const [language, setLanguage] = useState("EN");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleSubmit = () => {
    if (!message.trim()) {
      setError(true);
      return;
    }
    const payload = { language: language, msgText: message };
    handleSendNotification(payload);
  };

  const handleMessageChange = (e) => {
    const value = e.target.value;
    setMessage(value);
    if (value.trim()) {
      setError(false); // Clear error if the user starts typing
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Send Notification
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 16,
          }}
        >
          <Select value={language} onChange={handleLanguageChange}>
            <MenuItem value={"EN"}>English</MenuItem>
            <MenuItem value={"LU"}>Luganda</MenuItem>
            <MenuItem value={"RY"}>Runyakitara</MenuItem>
            {/* Add other languages here */}
          </Select>
        </div>
        <TextField
          multiline
          rows={4}
          placeholder="Enter the content of the update notification here..."
          fullWidth
          value={message}
          onChange={handleMessageChange}
          error={error}
          helperText={error ? "This field cannot be empty." : ""}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={!message.trim()} // Disable button if text is empty
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotificationDialog;
