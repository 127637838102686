import {
  Box,
  Button,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import DriveFolderUploadRoundedIcon from "@mui/icons-material/DriveFolderUploadRounded";
const fileUrl = process.env.PUBLIC_URL + "/Sample temp. for bulk upload .xlsx";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { URL } from "../../../../constants/URL";
import { createNearBy, getNearByList } from "../../../../store/actions/nearBy";
import { BULK_KEYS } from "./const";

const BulkImport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [jsonData, setJsonData] = useState(null);
  const [progress, setProgress] = useState(0); // Progress state
  const [parsing, setParsing] = useState(false);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onprogress = (e) => {
      if (e.lengthComputable) {
        const percentLoaded = (e.loaded / e.total) * 100;
        setProgress(percentLoaded);
      }
    };

    reader.onloadstart = () => {
      setParsing(true);
      setProgress(0);
    };

    reader.onload = (e) => {
      setTimeout(async () => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);

        const items = jsonData.map((item) => {
          const fillData = (keyEN, keyLU, KeyRY) => {
            const valueEN = item[keyEN];
            const valueLU = item[keyLU];
            const valueRY = item[KeyRY];

            //If want to get the same data for both languages if one is null than uncomment this.

            if (
              (valueEN !== null && valueEN !== undefined) ||
              (valueLU !== null && valueLU !== undefined) ||
              (valueRY !== null && valueRY !== undefined)
            ) {
              return {
                // EN: valueEN ? valueEN : valueLU || "",
                // LU: valueLU ? valueLU : valueEN,
                EN: valueEN ? valueEN : "",
                LU: valueLU ? valueLU : "",
                RY: valueRY ? valueRY : "",
              };
            } else {
              return { EN: "", LU: "", RY: "" };
            }

            // return { EN: valueEN, LU: valueLU };
          };

          return {
            fullname: fillData(
              BULK_KEYS.FULL_NAME_EN,
              BULK_KEYS.FULL_NAME_LU,
              BULK_KEYS.FULL_NAME_RY
            ),
            addressLine1: fillData(
              BULK_KEYS.ADDRESS_EN,
              BULK_KEYS.ADDRESS_LU,
              BULK_KEYS.ADDRESS_RY
            ),
            phone: item?.phone ? item?.phone.toString() : "",
            isAuthRequired: false,
            fullTimeIncharge: fillData(
              BULK_KEYS.FULL_TIME_INCHARGE_EN,
              BULK_KEYS.FULL_TIME_INCHARGE_LU,
              BULK_KEYS.FULL_TIME_INCHARGE_RY
            ),
            district: fillData(
              BULK_KEYS.DISTRICT_EN,
              BULK_KEYS.DISTRICT_LU,
              BULK_KEYS.DISTRICT_RY
            ),
            species: fillData(
              BULK_KEYS.SPECIES_EN,
              BULK_KEYS.SPECIES_LU,
              BULK_KEYS.SPECIES_RY
            ),
            // Add more keys as needed following the same pattern
          };
        });

        const hasNullData = (data) => {
          return data.some((item) => {
            return Object.values(item).some((value) => {
              // if (item.phone === "") return true;
              return value?.EN === "" && value?.LU === "" && value?.RY === "";
            });
          });
        };

        const nullDataExists = hasNullData(items);

        function checkPhoneLength(array) {
          return array.every((obj) => obj.phone.length <= 12);
        }

        const phoneLength = checkPhoneLength(items);

        setParsing(false);

        if (nullDataExists) {
          toast.error("Please add complete record for at least one language.");
        } else if (!phoneLength) {
          toast.error("Phone range should not be more than 12");
        } else {
          try {
            await dispatch(createNearBy(URL.NEARBYHELP, { items })).then(() => {
              dispatch(getNearByList(URL.NEARBYHELP));
            });
            toast.success(`Record Added Successfully`);
            navigate("/NearBy");
          } catch (error) {
            toast.error(`Failed to Add Record!`);
          }
        }
      }, 2000); // Simulate a 1-second delay before setting parsing to false
    };

    reader.readAsBinaryString(file);
  };

  useEffect(() => {
    if (parsing) {
      // Simulate progress by incrementing it over time
      let fakeProgress = 2;
      const interval = setInterval(() => {
        fakeProgress += 2;
        if (fakeProgress >= 100) {
          clearInterval(interval);
          fakeProgress = 100; // Ensure the progress doesn't exceed 100
        }
        setProgress(fakeProgress);
      }, 100);
    }
  }, [parsing]);
  return (
    <>
      <Typography variant="h5">{"Nearby Help Bulk Import"}</Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          border: "1px solid #e1e1e1",
          marginTop: "1rem",
          padding: "12px",
          alignItems: "center",
          borderRadius: "6px",
        }}
      >
        <Typography>
          {
            "Please download the template, add your entries and upload the file below."
          }
        </Typography>
        <a href={fileUrl} download>
          <Button variant="contained" size="sm">
            {" "}
            Download Template
          </Button>
        </a>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginTop="1.5rem"
        padding="10rem"
        border="dashed 1px #ccc"
        height={"400px"}
        borderRadius={"6px"}
      >
        {parsing ? (
          <>
            <CircularProgress variant="determinate" value={progress} />
            <Typography variant="caption">{`${Math.round(
              progress
            )}%`}</Typography>
          </>
        ) : (
          <>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input
                hidden
                accept=".xlsx, .xlx"
                type="file"
                onChange={handleFileUpload}
              />
              <DriveFolderUploadRoundedIcon
                style={{ fontSize: "4em", color: "#D9D9D9" }}
              />
            </IconButton>
            <Typography variant="caption" align="center">
              drag and drop the file here or upload manually. <br /> Supported
              formats .xlsx or .xlx
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};

export default BulkImport;
