import { Button } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  createResource,
  editResource,
  ListingRecord,
} from "../../../../store/actions/learningResource";
import {
  createNearBy,
  editNearBy,
  getNearByList,
} from "../../../../store/actions/nearBy";
import {
  localizedContentSelector,
  localizedVideoSelector,
  thumbnailSelector,
} from "../../../../store/reducers/selector";
import { validateNearBy } from "../../../validations/ValidateNearby";
import usePrompt from "../../../hook/usePrompt";
import Preview from "../../../preview/Preview";
import { toast } from "react-toastify";
import { URL } from "../../../../constants/URL";
import {
  localizedNearByAddressSelector,
  localizedNearByDistrictSelector,
  localizedNearByInchargeSelector,
  localizedNearByPhoneSelector,
  localizedNearByNameSelector,
} from "../../../../store/reducers/selector";
import { Language } from "@mui/icons-material";

const NearByButton = ({ error, addResourceUrl, ListingUrl }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [previewOpen, setPreviewOpen] = useState(false);
  const nearBy = useSelector((state) => state.nearBy);
  const [isPublished, setIsPublished] = useState(true);
  const address = useSelector(localizedNearByAddressSelector);
  const fullname = useSelector(localizedNearByNameSelector);
  const district = useSelector(localizedNearByDistrictSelector);
  const incharge = useSelector(localizedNearByInchargeSelector);
  const species = useSelector((state) => state.nearBy.species);
  const phone = useSelector(localizedNearByPhoneSelector);

  const handlePreviewOpen = () => {
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  const handleSaveResource = async (status) => {
    const validationError = validateNearBy(
      address,
      fullname,
      district,
      incharge,
      species,
      phone
    );
    if (validationError) {
      toast.error(validationError);
      return;
    }

    let filteredObject = nearBy;

    if (id) {
      filteredObject = {
        fullname: nearBy.fullname,
        addressLine1: nearBy.addressLine1,
        phone: nearBy.phone,
        fullTimeIncharge: nearBy.fullTimeIncharge,
        isAuthRequired: nearBy.isAuthRequired,
        district: nearBy.district,
        species: nearBy.species,
        // metaData: nearBy.metaData,
      };
    } else {
      filteredObject = {
        items: [
          {
            fullname: nearBy.fullname,
            addressLine1: nearBy.addressLine1,
            phone: nearBy.phone,
            fullTimeIncharge: nearBy.fullTimeIncharge,
            isAuthRequired: nearBy.isAuthRequired,
            district: nearBy.district,
            species: nearBy.species,
            // metaData: nearBy.metaData,
          },
        ],
      };
    }

    // let updatedItems = "";
    // if (!id) {
    //   // updatedItems = filteredObject?.items.map((item) => {
    //   //   const updatedItem = { ...item };
    //   //   // Check each key in the object
    //   //   Object.keys(updatedItem).forEach((key) => {
    //   //     const valueEN = updatedItem[key]?.EN;
    //   //     const valueLU = updatedItem[key]?.LU;
    //   //     // Check if EN has a value but LU is empty
    //   //     if (valueEN && !valueLU) {
    //   //       updatedItem[key].LU = valueEN; // Assign EN value to LU
    //   //     } else if (valueLU && !valueEN) {
    //   //       updatedItem[key].EN = valueLU; // Assign LU value to EN
    //   //     }
    //   //     // If both have values or both are empty, no action is needed
    //   //   });
    //   // return updatedItem;
    //   // });
    // }

    try {
      if (id) {
        await dispatch(editNearBy(addResourceUrl, id, filteredObject)).then(
          () => {
            dispatch(getNearByList(ListingUrl));
          }
        );
        toast.success(`NearBy edited successfully!`);
      } else {
        await dispatch(createNearBy(addResourceUrl, filteredObject)).then(
          () => {
            dispatch(getNearByList(URL.NEARBYHELP));
          }
        );
        toast.success("NearBy added successfully");
      }
      navigate("/nearby");
    } catch (error) {
      toast.error(`Failed to ${id ? "edit" : "create"} NearBy!`);
    }
  };

  // const handleCreateArticle = () => {
  //     setIsPublished(false);
  //     handleSaveResource("Draft");
  // };

  const handlePublishArticle = () => {
    setIsPublished(false);
    handleSaveResource("Published");
  };

  // const isBlocking = () => {
  //     return (
  //         (nearBy.title.length > 0 ||
  //             localizedContent.length > 0 ||
  //             localizedVideo ||
  //             localizedThumbail ||
  //             nearBy.coverImage['EN']) &&
  //         isPublished
  //     );
  // };

  // usePrompt('Are you sure you want to leave?', isBlocking());

  return (
    <div
      style={{ display: "flex", justifyContent: "center", margin: "10px 0" }}
    >
      {/* <Button
                size="small"
                variant="contained"
                onClick={handlePreviewOpen}
                style={{ margin: 10 }}
            >
                Preview
            </Button>
            <Preview open={previewOpen} handleClose={handlePreviewClose} />
            {
                <Button
                    size="small"
                    variant="contained"
                    onClick={handleCreateArticle}
                    style={{ margin: 10 }}
                >
                    Draft
                </Button>
            } */}
      <Button
        size="small"
        variant="contained"
        onClick={handlePublishArticle}
        style={{ margin: 10 }}
      >
        Publish
      </Button>
    </div>
  );
};

export default NearByButton;
