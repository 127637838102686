import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "./components/Header";
import {
  addFAQ,
  editFAQ,
  fetchFAQbyId,
  resetFormData,
  setLanguage,
  updateFormData,
} from "../../../store/actions/faqs";
import RTextArea from "./../../../component/formFields/RTextArea";
import { useFormik } from "formik";
import { setFaqCategoryLanguage } from "../../../store/actions/faqCategories";
import { toast } from "react-toastify";
import Categories from "./components/Categories";
import { prepareFaqPayload } from "../../../component/common/GeneralHelper";
import Feedback from "./components/Feedback";
import { Box } from "@mui/material";
const AddFaqs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useSelector((state) => state.faqs.selectedLanguage);
  const loading = useSelector((state) => state.faqs.loading);
  const [formData, setFormData] = useState(
    useSelector((state) => state.faqs.formData)
  );

  const faqData = useSelector((state) => state.faqs.formData);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(fetchFAQbyId(id)).then((res) => {
        if (res) {
          let data = res;
          data["categoryIds"] = res.categories;
          dispatch(updateFormData(res));
          formik.setFieldValue("title", res?.title[language]);
          formik.setFieldValue("description", res?.description?.[language]);
          formik.setFieldValue("categoryIds", res?.categories);
        }
      });
    }
    return () => {
      dispatch(resetFormData());
      dispatch(setLanguage("EN"));
    };
  }, []);

  useEffect(() => {
    formik.setFieldValue("title", faqData?.title?.[language]);
    formik.setFieldValue("description", faqData?.description?.[language]);
  }, [language]);

  const handleLanguage = (event) => {
    const language = event.target.value;
    dispatch(setLanguage(language));
    dispatch(setFaqCategoryLanguage(language));
  };

  const handleSubmit = () => {
    if (!faqData.categoryIds.length) {
      toast.error("Category is Required");
      return;
    }
    if (!faqData.title[language]) {
      toast.error("Title is Required");
      return;
    }
    if (!faqData.description[language]) {
      toast.error("Answer is Required");
      return;
    }
    faqData["status"] = "Published";

    let action = id ? editFAQ : addFAQ;
    dispatch(action(prepareFaqPayload(faqData, id)))
      .then((res) => {
        if (res.status === "success") {
          dispatch(resetFormData());
          formik.resetForm();
          navigate("/faqs");
        }
      })
      .catch((e) => toast.error(e));
  };

  const handleSaveDraft = () => {
    if (!faqData.categoryIds.length) {
      toast.error("Category is Required");
      return;
    }
    if (!faqData.title[language]) {
      toast.error("Title is Required");
      return;
    }
    if (!faqData.description[language]) {
      toast.error("Answer is Required");
      return;
    }

    faqData["status"] = "Draft";
    let action = id ? editFAQ : addFAQ;
    dispatch(action(prepareFaqPayload(faqData, id)))
      .then((res) => {
        if (res.status === "success") {
          dispatch(resetFormData());
          formik.resetForm();
          navigate("/faqs");
        }
      })
      .catch((e) => toast.error(e));
  };

  const formik = useFormik({
    initialValues: {
      title: faqData?.title[language] ?? "",
      description: faqData?.description[language] ?? "",
      categoryIds: faqData?.categoryIds ?? "",
    },
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const handleFieldChange = (fieldName, value) => {
    const updatedFormData = { ...faqData };
    if (fieldName === "categoryIds") {
      updatedFormData[fieldName] = value;
    } else {
      updatedFormData[fieldName][language] = value;
    }
    dispatch(updateFormData(updatedFormData)); // Dispatch updated data to Redux store
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Header
        route="/faqs"
        title={
          faqData?.title?.[language] !== ""
            ? faqData?.title?.[language]
            : formik?.values?.title
        }
        language={language}
        handleLanguage={handleLanguage}
        handleFieldChange={handleFieldChange}
        handleSaveDraft={() => handleSaveDraft()}
      />
      <Box sx={{mb: 1.5}}>
      <RTextArea
        name={`description`}
        value={formik.values.description ?? ""}
        error={formik.touched.description && Boolean(formik.errors.description)}
        helperText={formik.touched.description && formik.errors.description}
        label="Answer *"
        setHtmlImage={(filename) => {}}
        onChange={(e) => {
          formik.handleChange(e); // Update Formik's state
          handleFieldChange("description", e.target.value); // Update Redux
        }}
      />
      </Box>

      <Box sx={{mb: 2}}>
      <Categories
        language={language}
        selected={faqData?.categoryIds ?? []}
        handleFieldChange={handleFieldChange}
      />
      </Box>

      <Box sx={{mb: 2}}>
      {id && <Feedback faq={faqData} />}
      </Box>
    </form>
  );
};

export default AddFaqs;
