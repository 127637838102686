import React, { useState } from "react";
import DriveFolderUploadRoundedIcon from "@mui/icons-material/DriveFolderUploadRounded";
import {
  Badge,
  CircularProgress,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import BlobService from "../../services/blob-service";
import { WithHeader } from "./WithHeader";
import Resizer from "react-image-file-resizer";
import { validateImage } from "./HelperMethods";
import WarningCard from "./WarningCard";

const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20 MB in bytes

const CoverImage = ({
  setCoverImage,
  selectedCoverImage,
  requiredAspectRatio,
  recommendedSize,
  TOLERANCE,
}) => {
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState(false);

  // const MAX_WIDTH = 360;
  // const MAX_HEIGHT = 257;

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];
      const fileFormat = file.type;
      const fileSize = file.size / 1024 / 1024; // in MB

      if (!allowedFormats.includes(fileFormat)) {
        setError("Please select a JPG/JPEG/PNG file.");
        return;
      }

      if (fileSize > MAX_FILE_SIZE) {
        setError("Please select a file within 20 MB.");
        return;
      }
      const img = new Image();
      img.src = URL.createObjectURL(file);
      //Just check
      img.onload = () => {
        const validationError = validateImage(img, requiredAspectRatio);
        if (validationError) {
          setPopUpMessage(validationError);
          setOpenPopup(true);
        }
        setLoading(true);
        const filename = BlobService.generateFileName(file);
        BlobService.uploadImage(filename, file)
          .then(() => {
            setError(null);
            setLoading(false);
            dispatch(setCoverImage(filename));
          })
          .catch(() => {
            setError("Image upload failed. Please try again.");
            setLoading(false);
          });
      };
    }
  };

  const deleteFile = () => {
    // BlobService.deleteImage(selectedCoverImage).then(() => {
    dispatch(setCoverImage(""));
    // });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];

    if (file) {
      const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];
      const fileFormat = file.type;
      const fileSize = file.size / 1024 / 1024; // in MB

      if (!allowedFormats.includes(fileFormat)) {
        setError("Please select a JPG/JPEG/PNG file.");
        return;
      }

      if (fileSize > MAX_FILE_SIZE) {
        setError("Please select a file within 20 MB.");
        return;
      }
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const validationError = validateImage(
          img,
          requiredAspectRatio,
          TOLERANCE
        );
        if (validationError) {
          setPopUpMessage(validationError);
          setOpenPopup(true);
        }
        setLoading(true);
        const filename = BlobService.generateFileName(file);
        BlobService.uploadImage(filename, file)
          .then(() => {
            setError(null);
            setLoading(false);
            dispatch(setCoverImage(filename));
          })
          .catch(() => {
            setError("Image upload failed. Please try again.");
            setLoading(false);
          });
      };
    }
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    setPopUpMessage(null);
  };

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{
            border: 1,
            borderColor: "#f1f1f1",
            height: 300,
            position: "relative",
          }}
        >
          <CircularProgress
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </Box>
      ) : selectedCoverImage ? (
        <Box 
          sx={{padding: '16px',}} 
          display="flex" 
          flexDirection="column" 
          alignItems={"center"}
        >
          <Badge badgeContent="X" color="secondary" onClick={deleteFile}>
            <img
              src={BlobService.getImageUri(selectedCoverImage)}
              style={{ height: "16em" }}
              alt="Selected file"
            />
          </Badge>
        </Box>
      ) : (
        <Box
          display="flex"
          sx={{ m: 5 }}
          flexDirection="column"
          alignItems={"center"}
          justifyContent="center"
          textAlign="center"
        >
          {" "}
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input
              hidden
              accept=".jpeg, .png, .jpg"
              type="file"
              onChange={handleFileSelect}
            />
            <DriveFolderUploadRoundedIcon
              style={{ fontSize: "4em", color: "#D9D9D9" }}
            />
          </IconButton>
          <Box
            display="flex"
            marginTop="1rem"
            marginBottom="1rem"
            padding="3rem"
            border="dashed 1px #ccc"
            alignItems={"center"}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <Typography variant="caption">
              drag and drop the image here or upload manually. <br />
            </Typography>
          </Box>
          <Typography variant="caption">
            Max File Size: 20 MB <br />
            Recommended image resolution: {recommendedSize}
            <br /> Allowed formats: JPEG/JPG/PNG
          </Typography>
          {error && <Box color="red">{error}</Box>}
        </Box>
      )}
      {/* Popup for validation errors */}
      <WarningCard
        open={openPopup}
        handleClosePopup={handleClosePopup}
        recommendedSize={recommendedSize}
        popUpMessage={popUpMessage}
      ></WarningCard>
      {/* <Dialog open={openPopup} onClose={handleClosePopup}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <Typography>{popUpMessage}</Typography>
        </DialogContent>
        <DialogTitle>Image Size</DialogTitle>
        <DialogContent>
          <Typography>{`For the best viewing experience, please upload an image within the specified size range ${recommendedSize}`}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopup} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
};

export default WithHeader(CoverImage, "Cover Image");
