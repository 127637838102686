import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Address from "./Address";
import District from "./District";
import Phone from "./Phone";
import InChargeField from "./inCharge";
import { URL } from "../../../../constants/URL";
import FullName from "./FullName";
import NearByButton from "./NearByButton";
import {
  retrieveNearbyId,
  clearCurrentNearBy,
} from "../../../../store/actions/nearBy";
import NearByHeader from "../nearbyHeader";
import Species from "./Species";
import { SESSION_STORAGE } from "../../../../constants/GeneralConstant";
import { Box } from "@mui/material";

const NewNearBy = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.article.language);
  const [error, setError] = useState(false);

  const selectedCoverImage = useSelector(
    (state) => state.article.coverImage[lang]
  );

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(retrieveNearbyId(URL.NEARBYHELP, id));
    } else {
      dispatch(clearCurrentNearBy());
    }
  }, [dispatch, id]);

  useEffect(() => {
    sessionStorage.setItem(SESSION_STORAGE.SPEC_LANGUAGE, "EN");

    return () => {
      sessionStorage.setItem(SESSION_STORAGE.SPEC_LANGUAGE, null);
    };
  }, []);

  return (
    <>
      <NearByHeader></NearByHeader>
      {/* <RadioButtonList title={"Role"} list={list} selectedValue={selectedValue} onRadioChange={handleRadioChange}></RadioButtonList> */}
      {/* <CategorySelector /> */}
      <Box sx={{mb: 2}}>
      <FullName></FullName>
      </Box>

      <Box sx={{mb: 2}}>
      <Species />
      </Box>

      <Box sx={{mb: 2}}>
      <Address></Address>
      </Box>

      <Box sx={{mb: 2}}>
      <District></District>
      </Box>

      <Box sx={{mb: 2}}>
      <InChargeField></InChargeField>
      </Box>

      <Box sx={{mb: 2}}>
      <Phone></Phone>
      </Box>

      <Box sx={{mb: 2}}>
      <NearByButton
        addResourceUrl={URL.NEARBYHELP}
        ListingUrl={URL.NEARBYHELP}
        error={error}
      ></NearByButton>
      </Box>
    </>
  );
};

export default NewNearBy;
