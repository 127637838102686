import React, { useState } from "react";
import { Box, Stack, TextField } from "@mui/material";
import { Tooltip } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import BlobService from "../../../services/blob-service";
import {
  setLocalValue,
  setScientificValue,
} from "../../../store/actions/currentArticle";
import { useDispatch } from "react-redux";
import DeleteConfirmationDialog from "../../common/DeleteConfirmationDialog";

const TickInfo = ({ ticks, deleteFile }) => {
  const dispatch = useDispatch();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const handleLocalNameChange = (e, index) => {
    const text = e.target.value;
    dispatch(setLocalValue(text, index));
  };

  const handleScientificNameChange = (e, index) => {
    const text = e.target.value;
    dispatch(setScientificValue(text, index));
  };

  const handleDeleteConfirmationOpen = (index, tick) => {
    setDeleteConfirmationOpen({ index, tick });
  };

  const getTickRow = (tick, index) => {
    return (
      <Box
        key={tick.image}
        sx={{
          backgroundColor: "white",
          height: "100%",
          p: 2.5,
          border: 1,
          borderRadius: "0.2em 0.2em 0 0",
          borderColor: "lightgrey",
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={1}
          sx={{ m: 1 }}
        >
          <img
            style={{ height: "3em" }}
            src={BlobService.getImageUri(tick.image)}
            alt="Uploaded file"
          />
          <TextField
            id={`localName-${index}`}
            required
            size="small"
            label="Local Name"
            value={tick.commonName ? tick.commonName : ""}
            variant="outlined"
            onChange={(e) => handleLocalNameChange(e, index)}
            fullWidth
            placeholder="Local name..."
          />
          <TextField
            id={`scientificName-${index}`}
            required
            size="small"
            label="Scientific Name"
            value={tick.scientificName ? tick.scientificName : ""}
            variant="outlined"
            onChange={(e) => handleScientificNameChange(e, index)}
            fullWidth
            placeholder="Scientific name..."
          />
          <Tooltip title="Delete">
            <DeleteOutlineOutlinedIcon
              onClick={() => handleDeleteConfirmationOpen(index, tick)}
            />
          </Tooltip>
        </Stack>
      </Box>
    );
  };

  return (
    <>
      {ticks && ticks?.map((tick, index) => getTickRow(tick, index))}
      <DeleteConfirmationDialog
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onDelete={() => {
          deleteFile(
            deleteConfirmationOpen.tick.image,
            deleteConfirmationOpen.index
          );
        }}
      />
    </>
  );
};

export default TickInfo;
