import React, { useEffect } from "react";
import ProductHeader from "./ProductHeader";
import CoverImage from "../../common/CoverImage";
import About from "./About";
import {
  clearCurrentProduct,
  retrieveProductbyId,
  setCoverImage,
  setHtmlImage,
} from "../../../store/actions/currentProduct";
import { useDispatch, useSelector } from "react-redux";
import Application from "./Application";
import Dosage from "./Dosage";
import Species from "./Species";
import { useParams } from "react-router-dom";
import MultiAttachment from "./MultiAttachment";
import ProductButton from "./ProductButton";
import { Box } from "@mui/material";

const recommendedSizeMessage =
  "Best results with a 16:9 image, like 640x360 or 960x540.";

const Product = () => {
  const dispatch = useDispatch();
  const languageSelector = useSelector(
    (state) => state.currentProduct.language
  );
  const selectedCoverImage = useSelector(
    (state) => state.currentProduct.coverImage[languageSelector]
  );
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(retrieveProductbyId(id));
    } else {
      dispatch(clearCurrentProduct());
    }
  }, [dispatch, id]);

  const requiredAspectRatio = 16 / 9;

  return (
    <>
      <ProductHeader />

      <Box sx={{mb: 2}}>
        <CoverImage
          setCoverImage={setCoverImage}
          selectedCoverImage={selectedCoverImage}
          recommendedSize={recommendedSizeMessage}
          requiredAspectRatio={requiredAspectRatio}
        />
      </Box>

      <Box sx={{mb: 2}}>
        <Species />
      </Box>

      <Box sx={{mb: 2}}>
        <About setHtmlImage={setHtmlImage} />
      </Box>
      
      <Box sx={{mb: 2}}>
      <Application setHtmlImage={setHtmlImage} />
      </Box>

      <Box sx={{mb: 2}}>
      <Dosage setHtmlImage={setHtmlImage} />
      </Box>

      <Box sx={{mb: 2}}>
      <MultiAttachment />
      </Box>

      <Box sx={{mb: 2}}>
      <ProductButton />
      </Box>
    </>
  );
};

export default Product;
