import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_MESSAGE,
  SET_MESSAGE,
  FORGET_PASSWORD_FAIL,
  FORGET_PASSWORD_SUCCESS,
  SECURITY_CODE_SUCCESS,
  SECURITY_CODE_FAIL,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAIL,
  PERMISSIONS_SUCCESS,
  PERMISSIONS_FAIL,
  EXPIRY_TOKEN_SUCCESS,
  EXPIRY_TOKEN_FAIL,
} from "./type";
import AuthService from "../../services/auth.service";
import { trackPromise } from "react-promise-tracker";
import api from "../../services/api";
import { EXPIRY_TOKEN_PERMISSIONS } from "../../constants/apiUrls";

export const login = (email, password) => (dispatch) =>
  AuthService.login(email, password).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });
      dispatch({
        type: CLEAR_MESSAGE,
      });

      return Promise.resolve(data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );

export const forgetPassword = (email) => (dispatch) =>
  AuthService.forgetpassword(email).then(
    (data) => {
      dispatch({
        type: FORGET_PASSWORD_SUCCESS,
        payload: { user: data },
      });
      dispatch({
        type: CLEAR_MESSAGE,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: FORGET_PASSWORD_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );

export const secuirtyCode = (token) => (dispatch) =>
  AuthService.securityToken(token).then(
    (data) => {
      dispatch({
        type: SECURITY_CODE_SUCCESS,
        payload: { user: data },
      });
      dispatch({
        type: CLEAR_MESSAGE,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SECURITY_CODE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );

export const setpassword = (email, token, password) => (dispatch) =>
  AuthService.resetPassword(email, token, password).then(
    (data) => {
      dispatch({
        type: PASSWORD_RESET_SUCCESS,
        payload: { user: data },
      });
      dispatch({
        type: CLEAR_MESSAGE,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: PASSWORD_RESET_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );

export const permissions = (id) => (dispatch) =>
  AuthService.permissions(id).then(
    (data) => {
      dispatch({
        type: PERMISSIONS_SUCCESS,
        payload: data,
      });
      dispatch({
        type: CLEAR_MESSAGE,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: PERMISSIONS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};

export const verifyTokenExpiry = (token) => async (dispatch) => {
  try {
    const response = await trackPromise(
      api.post(
        EXPIRY_TOKEN_PERMISSIONS,
        {
          token,
        },
        "v2"
      )
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error.message);
  }
};
// export const refreshToken = (accessToken) => (dispatch) => {
//   dispatch({
//     type: REFRESH_TOKEN,
//     payload: accessToken,
//   });
// };
