import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "./components/Header";

import { useFormik } from "formik";
import {
  createAdmin,
  getAdminById,
  getAllPermissions,
  resetFormData,
  updateAdmin,
  updateFormData,
} from "../../../store/actions/users";
import { TextField } from "@mui/material";
import UserForm from "./components/UserForm";
import { toast } from "react-toastify";
import { EMAIL_REGEX } from "../../../constants/GeneralConstant";
import { Palette } from "@mui/icons-material";

const AddUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector((state) => state.users.formData);

  const { id } = useParams();

  useEffect(() => {
    dispatch(
      getAllPermissions({
        isMobileBased: false,
      })
    );
    if (id) {
      dispatch(getAdminById(id)).then((res) => {
        if (res) {
          let { data } = res;
          dispatch(updateFormData(data));
          formik.setFieldValue("email", data?.email);
          formik.setFieldValue("firstname", data?.firstname);
          formik.setFieldValue("lastname", data?.lastname);
        }
      });
    }
    return () => {
      dispatch(resetFormData());
      formik.resetForm();
    };
  }, []);

  const handleSubmit = () => {
    const { firstname, lastname, email, permissions } = userData;

    if (!firstname.length) {
      toast.error("First Name is Required");
      return;
    }

    if (!lastname.length) {
      toast.error("Last Name is Required");
      return;
    }

    if (!email.length) {
      toast.error("Email is Required");
      return;
    } else {
      const isValidEmail = EMAIL_REGEX.test(email);
      !isValidEmail && toast.error("Email is incorrect");
    }

    if (permissions.length === 0) {
      toast.error("First Name is Required");
      return;
    }

    let payload = {
      email: userData.email,
      lastname: userData.lastname,
      firstname: userData.firstname,
      permissionIds: userData.permissions,
    };
    let action = id
      ? () => updateAdmin(id, payload)
      : () => createAdmin(payload);
    dispatch(action())
      .then((res) => {
        if (res.status === "success") {
          dispatch(resetFormData());
          formik.resetForm();
          navigate("/users");
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const handleFieldChange = (fieldName, value) => {
    const updatedFormData = { ...userData };
    updatedFormData[fieldName] = value;
    dispatch(updateFormData(updatedFormData)); // Dispatch updated data to Redux store
    formik.setFieldValue("email", updatedFormData?.email);
    formik.setFieldValue("firstname", updatedFormData?.firstname);
    formik.setFieldValue("lastname", updatedFormData?.lastname);
  };

  return (
    <form style={{ marginBottom: 20 }} onSubmit={formik.handleSubmit}>
      <Header
        formik={formik}
        route="/users"
        handleFieldChange={handleFieldChange}
      />
      <UserForm
        isEdit={!!id ?? false}
        formik={formik}
        handleFieldChange={handleFieldChange}
      />
    </form>
  );
};

export default AddUser;
