import { Button, Box } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { editResource } from "../../../store/actions/learningResource";
import Preview from "../preview/Preview";
import {
  getLocalizedTicks,
  localizedContentSelector,
} from "../../../store/reducers/selector";
import usePrompt from "../../hook/usePrompt";
import { toast } from "react-toastify";
import { URL } from "../../../constants/URL";

const KYTButton = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isPublished, setIsPublished] = useState(true);
  const resource = useSelector((state) => state.article);
  const localizedTick = useSelector(getLocalizedTicks);
  const localizedContent = useSelector(localizedContentSelector);
  const [previewOpen, setPreviewOpen] = useState(false);

  const handlePreviewOpen = () => {
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  const handleCreateArticle = () => {
    const atLeastOneImage = resource.ticks.some((tick) => tick.image);

    const allTicksHaveCommonName = resource.ticks.every((tick) => {
      const { commonName } = tick;
      return commonName && (commonName.EN || commonName.LU || commonName.RY);
    });
    const allTicksHaveScientificName = resource.ticks.every((tick) => {
      const { scientificName } = tick;
      return (
        scientificName &&
        (scientificName.EN || scientificName.LU || scientificName.RY)
      );
    });

    if (!allTicksHaveCommonName) {
      toast.error("Please provide a local name.");
      return;
    }

    if (!allTicksHaveScientificName) {
      toast.error("Please provide a scientific name.");
      return;
    }
    if (!atLeastOneImage) {
      toast.error("Please provide at least one image for the ticks.");
      return;
    }
    if (resource.title === "") {
      toast.error("Title can't be blank");
    } else if (!localizedContent) {
      toast.error("Please enter some text in the editor.");
    } else {
      switch (resource.learningResourceType) {
        case "Tick":
          delete resource.authorId;
          break;
        default:
          break;
      }
      resource.status = "Draft";
      setIsPublished(false);

      dispatch(editResource(URL.RESOURCE, id, resource))
        .then(() => {
          navigate(`/know-your-tick`);
          toast.success(
            `${resource.learningResourceType} edited successfully!`
          );
        })
        .catch(() => {
          toast.error(`Failed to create ${resource.learningResourceType}!`);
        });
    }
  };

  const handlePublishArticle = () => {
    const allTicksHaveCommonName = resource.ticks.every((tick) => {
      const { commonName } = tick;
      return commonName && (commonName.EN || commonName.LU || commonName.RY);
    });
    const atLeastOneImage = resource.ticks.some((tick) => tick.image);

    const allTicksHaveScientificName = resource.ticks.every((tick) => {
      const { scientificName } = tick;
      return (
        scientificName &&
        (scientificName.EN || scientificName.LU || scientificName.RY)
      );
    });

    if (!allTicksHaveCommonName) {
      toast.error("Please provide a local name.");
      return;
    }

    if (!allTicksHaveScientificName) {
      toast.error("Please provide a scientific name.");
      return;
    }
    if (!atLeastOneImage) {
      toast.error("Please provide at least one image for the ticks.");
      return;
    }

    if (resource.title === "") {
      toast.error("Title can't be blank");
    } else if (!localizedContent) {
      toast.error("Please enter some text in the editor.");
    } else {
      switch (resource.learningResourceType) {
        case "Tick":
          delete resource.authorId;
          delete resource.authorId;
          break;
        default:
          break;
      }
      resource.status = "Published";
      setIsPublished(false);
      delete resource.title;
      delete resource.publishedAt;

      dispatch(editResource(URL.RESOURCE, id, resource))
        .then(() => {
          navigate(`/know-your-tick`);
          toast.success(
            `${resource.learningResourceType} edited successfully!`
          );
        })
        .catch(() => {
          toast.error(`Failed to create ${resource.learningResourceType}!`);
        });
    }
  };

  const isBlocking = () => {
    return (resource?.title?.length > 0 || localizedTick) && isPublished;
  };

  usePrompt("Are you sure you want to leave?", isBlocking());

  return (
    <Box
      sx={{ display: "flex", 
        justifyContent: "center", 
        gap: 2,
        mb: 2, 
      }}
    >
      {" "}
      <Button
        size="small"
        variant="contained"
        onClick={handlePreviewOpen}
      >
        Preview
      </Button>
      <Preview open={previewOpen} handleClose={handlePreviewClose} />
      <Button
        size="small"
        variant="contained"
        onClick={handleCreateArticle}
      >
        Draft
      </Button>
      <Button
        size="small"
        variant="contained"
        onClick={handlePublishArticle}
      >
        Publish
      </Button>
    </Box>
  );
};

export default KYTButton;
