// External imports
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  CircularProgress,
  Alert,
  Link,
} from "@mui/material";
import { toast } from "react-toastify";
import "./style.scss";

// Internal imports
import { clearMessage } from "../../store/actions/message";
import { resendToken, setupPassword } from "../../store/actions/users";
import { verifyTokenExpiry } from "../../store/actions/auth";
import FormValidation from "../common/FormValidations";

function SetupPassword() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { message } = useSelector((state) => state.message);

  const [loading, setLoading] = useState(false);
  const [resendMessage, setResendMessage] = useState("");
  const [tokenExpired, setTokenExpired] = useState(false);

  // Extract token from URL
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: FormValidation.SetupPasswordSchema,
    onSubmit: (values, actions) => {
      const payload = { ...values, token };
      setLoading(true);
      dispatch(clearMessage());
      dispatch(setupPassword(payload))
        .then(() => {
          navigate({
            pathname: "/verify-otp",
            search: createSearchParams({
              email: values.email,
            }).toString(),
          });
          toast.success("Password setup successfully.");
        })
        .catch((err) => {
          setLoading(false);
          setTokenExpired(true);
          toast.error(err.response?.data?.message || "An error occurred.");
        })
        .finally(() => actions.setSubmitting(false));
    },
  });

  const handleResendLink = () => {
    if (!formik.values.email) {
      toast.error("Email is required.");
      return;
    }
    setResendMessage("Resending token...");
    dispatch(resendToken({ email: formik.values.email }))
      .then(() => setResendMessage("A new token has been sent to your email."))
      .catch((err) =>
        toast.error(err.response?.data?.message || "Error resending token.")
      );
  };

  useEffect(() => {
    setLoading(true);
    dispatch(verifyTokenExpiry(token))
      .catch(() => {
        setTokenExpired(true);
        toast.error("Token expired. Please resend the link.");
      })
      .finally(() => setLoading(false));
  }, [dispatch, token]);

  return (
    <>
      <Box
        component="main"
        sx={{
          display: "flex",
          minHeight: "100vh",
        }}
      >
        <Container
          maxWidth="xs"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
            padding: 4,
          }}
        >
          <Box sx={{ px: 3 }}>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mb: 3 }}>
                <Box
                  component="img"
                  src="/img/elanco-logo.svg"
                  alt="Elanco"
                  sx={{ height: "80px", objectFit: "cover", mb: 3 }}
                />
                <Typography
                  color="textPrimary"
                  variant="h4"
                  sx={{ mb: 1, fontSize: "1.5rem" }}
                >
                  Setup Password
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  Enter your email and set up a new password.
                </Typography>
              </Box>
              <TextField
                error={Boolean(formik.touched.email && formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                label="Email Address"
                margin="normal"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="email"
                value={formik.values.email}
                variant="outlined"
              />
              <TextField
                error={Boolean(
                  formik.touched.password && formik.errors.password
                )}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="password"
                value={formik.values.password}
                variant="outlined"
              />
              <TextField
                error={Boolean(
                  formik.touched.confirm_password &&
                    formik.errors.confirm_password
                )}
                fullWidth
                helperText={
                  formik.touched.confirm_password &&
                  formik.errors.confirm_password
                }
                label="Confirm Password"
                margin="normal"
                name="confirm_password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="password"
                value={formik.values.confirm_password}
                variant="outlined"
              />
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  // disabled={formik.isSubmitting || loading || tokenExpired}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {loading ? <CircularProgress size={20} /> : "Save"}
                </Button>
              </Box>
              {message && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {message}
                </Alert>
              )}
            </form>
            {tokenExpired && (
              <Typography>
                <Link
                  sx={{
                    textDecoration: "none",
                    fontSize: "14px",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                  href="#"
                  onClick={handleResendLink}
                >
                  Resend Link
                </Link>
              </Typography>
            )}
            {resendMessage && (
              <Typography sx={{ mt: 1 }} color="textSecondary">
                {resendMessage}
              </Typography>
            )}
          </Box>
        </Container>

        {/* Right: Image */}
        <Box
          className="hero-img"
          sx={{
            flex: 1,
            backgroundImage: "url(/img/elanco-admin-cattle.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: { xs: "none", md: "block" },
          }}
        ></Box>
      </Box>
    </>
  );
}

export default SetupPassword;
