import { localizedObject } from "../../component/common/GeneralHelper";
import api from "../../services/api";
import {
  CREATE_PRODUCT_SPECIES_FAILURE,
  CREATE_PRODUCT_SPECIES_SUCCESS,
  DELETE_SPECIES_FAILURE,
  DELETE_SPECIES_REQUEST,
  DELETE_SPECIES_SUCCESS,
  GET_PRODUCT_SPECIES_FAILURE,
  GET_PRODUCT_SPECIES_SUCCESS,
} from "./type";

export const getSpecies = () => async (dispatch) => {
  try {
    const response = await api.get("/species");
    dispatch({
      type: GET_PRODUCT_SPECIES_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_PRODUCT_SPECIES_FAILURE, payload: error.message });
  }
};

export const createSpecies = (title, lang) => async (dispatch) => {
  const titleData = localizedObject(lang, title);
  try {
    const response = await api.post("/species", {
      title: titleData,
    });
    dispatch({
      type: CREATE_PRODUCT_SPECIES_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: CREATE_PRODUCT_SPECIES_FAILURE, payload: error.message });
  }
};

export const deleteSpecies = (id) => (dispatch) => {
  dispatch({ type: DELETE_SPECIES_REQUEST });

  return new Promise((resolve, reject) => {
    api
      .delete(`/species/${id}`)
      .then((response) => {
        const data = response.data.data;
        dispatch({ type: DELETE_SPECIES_SUCCESS, payload: data });
        resolve(data); // Resolve the promise with the response data
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.message || error.message;
        dispatch({ type: DELETE_SPECIES_FAILURE, payload: errorMessage });
        reject(errorMessage); // Reject the promise with the error message
      });
  });
};
