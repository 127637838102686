import React, { useEffect, useState } from "react";
import ContentHeader from "../../common/ContentHeader";
import ProductTable from "./ProductTable";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { clearCurrentProduct } from "../../../store/actions/currentProduct";
import { getProducts } from "../../../store/actions/product";
import { clearCurrentEvent } from "../../../store/actions/currentEvent";
import { clearCurrentRescource } from "../../../store/actions/currentArticle";

const ProductListing = () => {
  const { products } = useSelector((state) => state.products);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id === undefined) {
      dispatch(clearCurrentProduct());
      dispatch(clearCurrentEvent());
      dispatch(clearCurrentRescource());
    }
    dispatch(getProducts());
  }, [dispatch, id]);

  const handleClearSearchQuery = (event) => {
    setSearchQuery("");
  };

  const filteredData = products.filter((item) => {
    const enTitle = item.title.EN ? item.title.EN.toLowerCase() : null;
    const lnTitle = item.title.LU ? item.title.LU.toLowerCase() : null;
    const ryTitle = item.title.RY ? item.title.RY.toLowerCase() : null;
    const searchQueryLowerCase = searchQuery.toLowerCase();

    return (
      (enTitle && enTitle.includes(searchQueryLowerCase)) ||
      (lnTitle && lnTitle.includes(searchQueryLowerCase)) ||
      (ryTitle && ryTitle.includes(searchQueryLowerCase))
    );
  });

  return (
    <>
      <ContentHeader
        header="Products"
        route="/new-product"
        searchQuery={searchQuery}
        onSearchQueryChange={handleSearchQueryChange}
        onClearSearchQuery={handleClearSearchQuery}
        searchPlaceholder="Search by Title"
        isNearBy={false}
        count={filteredData?.length ?? 0}
      />
      <ProductTable data={filteredData} />
    </>
  );
};

export default ProductListing;
