export const validateNearBy = (
  address,
  fullname,
  district,
  incharge,
  phone,
  species
) => {
  if (!fullname) {
    return "Fullname can't be blank";
  }
  if (!address) {
    return "Address can't be blank";
  }
  if (!district) {
    return "District can't be blank";
  }
  if (!incharge) {
    return "Incharge can't be blank";
  }
  if (!phone) {
    return "Phone can't be blank";
  }
  if (phone.length > 12) {
    return "Phone range should not be more than 12";
  }
  if (species.length === 0) {
    return "Species can't be empty";
  }
  return null;
};
