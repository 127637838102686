// src/redux/reducers/faqReducer.js
import {
  FETCH_FAQS_REQUEST,
  FETCH_FAQS_SUCCESS,
  FETCH_FAQS_FAILURE,
  ADD_FAQ_SUCCESS,
  EDIT_FAQ_SUCCESS,
  DELETE_FAQ_SUCCESS,
  UPDATE_FAQS_FORM,
  SET_FAQS_LANGUAGE,
  RESET_FAQS_FORM,
  TOGGLE_FAQ_ACTIVATION_SUCCESS,
  FETCH_FAQ_BY_ID_FAILURE,
  FETCH_FAQ_BY_ID_REQUEST,
  FETCH_FAQ_BY_ID_SUCCESS,
} from "../actions/type";

const initialState = {
  formData: {
    title: { EN: "", LU: "", RY: "" },
    description: { EN: "", LU: "", RY: "" },
    categoryIds: [],
    agreeCount: 0,
    disagreeCount: 0,
  },
  selectedLanguage: "EN",
  loading: false,
  list: {},
  faq: null,
  error: null,
};

const faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FAQS_LANGUAGE:
      return { ...state, selectedLanguage: action.payload };
    case UPDATE_FAQS_FORM:
      return {
        ...state,
        formData: action.payload,
      };
    case RESET_FAQS_FORM:
      return { ...state, formData: action.payload, faq: null };
    case FETCH_FAQS_REQUEST:
      return { ...state, loading: true };
    case FETCH_FAQS_SUCCESS:
      return { ...state, loading: false, list: action.payload };
    case FETCH_FAQS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_FAQ_BY_ID_REQUEST:
      return { ...state, loading: true };
    case FETCH_FAQ_BY_ID_SUCCESS:
      return { ...state, loading: false, faq: action.payload };
    case FETCH_FAQ_BY_ID_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case TOGGLE_FAQ_ACTIVATION_SUCCESS:
      return {
        ...state,
      };
    case ADD_FAQ_SUCCESS:
      return { ...state };
    case EDIT_FAQ_SUCCESS:
      return {
        ...state,
      };
    case DELETE_FAQ_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default faqReducer;
