import React, { useState } from "react";
import {
  TextField,
  FormControl,
  MenuItem,
  Select,
  Box,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";

import PreviewIcon from "@mui/icons-material/Preview";
import DraftsIcon from "@mui/icons-material/Drafts";
import FaqPreview from "../../../../component/preview/FaqPreview";

const Header = ({
  title,
  language,
  handleLanguage,
  handleFieldChange,
  handleSaveDraft,
  route,
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);

  const handlePreviewOpen = () => {
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  return (
    <>
      <FaqPreview open={previewOpen} handleClose={handlePreviewClose} />

      <Box
        sx={{
            display: "flex",
            mb: 1.5,
            pt: 0.75,
            pb: 2,
            borderBottom: '1px solid #E1E1E1',
            gap: 1,
          }}
        >
        <TextField
          id="title"
          name="title"
          size="small"
          className="title"
          required
          value={title || ""}
          variant="outlined"
          onChange={(e) => handleFieldChange("title", e.target.value)}
          fullWidth
          // sx={{ flex: '1 1 50%', mr: '0.01rem' }}
          placeholder={`Enter title for your FAQ here..`}
        />
        <FormControl
          size="small"
          className="selector"
          sx={{ minWidth: "10rem" }}
        >
          <Select
            onChange={handleLanguage}
            value={language}
            displayEmpty
            name="language"
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value={"EN"}>English</MenuItem>
            <MenuItem value={"LU"}>Luganda</MenuItem>
            <MenuItem value={"RY"}>Runyakitara</MenuItem>
          </Select>
        </FormControl>
      

      <Tooltip title="Preview">
        <IconButton
          onClick={handlePreviewOpen}
          style={{ cursor: "pointer" }}
          size="small"
          disableRipple
          disableFocusRipple
        >
          <PreviewIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Draft">
        <IconButton
          onClick={handleSaveDraft}
          style={{ cursor: "pointer" }}
          size="small"
          disableRipple
          disableFocusRipple
        >
          <DraftsIcon />
        </IconButton>
      </Tooltip>
      <Button
        sx={{width: '150px'}}
        variant="contained"
        size="small"
        type="submit"
        // onClick={() => navigate(route)}
      >
        Publish
      </Button>
      </Box>
    </>
  );
};

export default Header;
