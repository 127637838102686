import React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { WithHeader } from "../../../../component/common/WithHeader";

const CategoryManager = ({
  data = [], // List of items to display
  filteredData = [], // Filtered list based on search or other criteria
  onInputChange, // Handler for input change
  inputPlaceholder = "Start typing to create a new item", // Placeholder for input
  inputValue = "", // Controlled input value
  onAddClick, // Handler for add button click
  addButtonLabel = "Add", // Label for add button
  error = false, // Boolean indicating input error state
  errorMessage = "Item already exists!", // Error message
  generalErrorMessage = "", // General error message for the component
  renderRow, // Function to render a single row of data
  dialogComponent: DialogComponent, // Optional dialog component to render
  dialogProps = {}, // Props for the dialog component
  listStyle = {}, // Custom styles for the list container
}) => {
  return (
    <Box
      marginTop="1rem"
      borderRadius="0.2em 0.2em 0 0"
      borderColor="lightgrey"
    >
      {/* List Container */}
      <div style={{ maxHeight: "500px", overflowY: "auto", ...listStyle }}>
        {filteredData.length > 0 && filteredData.map((item) => renderRow(item))}
      </div>

      {/* Input Field */}
      <TextField
        fullWidth
        type="text"
        value={inputValue}
        onChange={onInputChange}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: 0, // Uniform border radius
          },
        }}
        placeholder={inputPlaceholder}
        InputProps={{
          endAdornment: inputValue && (
            <Button
              sx={{ marginRight: "-12px" }}
              variant="contained"
              onClick={onAddClick}
            >
              {addButtonLabel}
            </Button>
          ),
        }}
        error={!!error}
        helperText={error && errorMessage}
      />

      {/* General Error Message */}
      {generalErrorMessage && (
        <Typography
          color="error"
          variant="body2"
          sx={{ marginTop: "5px", fontWeight: "bold" }}
        >
          {generalErrorMessage}
        </Typography>
      )}

      {/* Optional Dialog */}
      {DialogComponent && <DialogComponent {...dialogProps} />}
    </Box>
  );
};

export default WithHeader(CategoryManager, "Categories *");
