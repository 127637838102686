import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Button,
  CardHeader,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Stack,
  Box,
} from "@mui/material";
import ConfirmationDialog from "../../dialogs/ConfirmationDialog";
import SelectionLimitDialog from "../../common/SelectionLimitDialog";

const CheckBoxListInput = ({
  items,
  selectedItems,
  onAddItem,
  onDeleteItem,
  onToggleItem,
  getItemTitle,
  maxSelectionLimit,
  dialogTitle,
  dialogContent,
  placeholder,
  addButtonText,
  allowAddItem = false,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const handleAddClick = () => {
    const duplicate = items.find(
      (item) => getItemTitle(item).toLowerCase() === inputValue.toLowerCase()
    );
    if (duplicate) {
      setError(true);
      return;
    }

    onAddItem(inputValue);
    setInputValue("");
  };

  const handleToggle = useCallback(
    (id) => {
      if (items.filter((item) => item.selected).length === maxSelectionLimit) {
        setDialogOpen(true);
        return;
      }

      onToggleItem(id);
    },
    [items, maxSelectionLimit, onToggleItem]
  );

  const handleDelete = (id) => {
    onDeleteItem(id).catch(() => setConfirmationDialogOpen(true));
  };

  const isChecked = (id) => {
    return selectedItems.includes(id); // Exclude objects with matching ids
  };

  return (
    <>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        title={dialogTitle}
        content={dialogContent}
        onClose={() => setConfirmationDialogOpen(false)}
      />

      <Box className="cs-scroll" sx={{ Maxheight: 350, overflowY: "auto" }}>
        <SelectionLimitDialog
          data="Items"
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
        />

        {items.map((item) => (
          <Stack
            key={item.id}
            alignItems="center"
            direction="row"
            justifyContent="flex-start"
          >
            <FormControlLabel
              sx={{
                m: 0,
              }}
              control={
                <Checkbox
                  checked={isChecked(item.id)}
                  onChange={() => handleToggle(item.id)}
                />
              }
            />
            <CardHeader
              title={getItemTitle(item)}
              sx={{
                p: 0,
              }}
              titleTypographyProps={{
                fontSize: "14px", // Adjusts the font size of the MuiTypography-root
              }}
            />
            <IconButton
              onClick={() => handleDelete(item.id)}
              aria-label="delete"
              size="small"
              sx={{
                ml: "auto",
                mr: 2,
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 20 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.75 23.25C3.0625 23.25 2.47417 23.0054 1.985 22.5163C1.49583 22.0271 1.25083 21.4383 1.25 20.75V4.5H0V2H6.25V0.75H13.75V2H20V4.5H18.75V20.75C18.75 21.4375 18.5054 22.0263 18.0163 22.5163C17.5271 23.0063 16.9383 23.2508 16.25 23.25H3.75ZM16.25 4.5H3.75V20.75H16.25V4.5ZM6.25 18.25H8.75V7H6.25V18.25ZM11.25 18.25H13.75V7H11.25V18.25Z"
                  fill="black"
                  fillOpacity="0.5"
                />
              </svg>
            </IconButton>
          </Stack>
        ))}
      </Box>

      {allowAddItem && (
        <TextField
          fullWidth
          type="text"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            setError(false);
          }}
          placeholder={placeholder}
          InputProps={{
            endAdornment: inputValue && (
              <Button
                size="sm"
                sx={{ width: "150px" }}
                variant="contained"
                onClick={handleAddClick}
              >
                {addButtonText}
              </Button>
            ),
          }}
          error={error}
          helperText={error && "Item already exists!"}
        />
      )}
    </>
  );
};

CheckBoxListInput.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
      title: PropTypes.object.isRequired,
    })
  ).isRequired,
  onAddItem: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  onToggleItem: PropTypes.func.isRequired,
  getItemTitle: PropTypes.func.isRequired,
  maxSelectionLimit: PropTypes.number,
  dialogTitle: PropTypes.string,
  dialogContent: PropTypes.string,
  placeholder: PropTypes.string,
  addButtonText: PropTypes.string,
};

CheckBoxListInput.defaultProps = {
  maxSelectionLimit: 5,
  dialogTitle: "Alert",
  dialogContent: "This item is associated with other entities.",
  placeholder: "Start typing to add a new item",
  addButtonText: "Add Item",
};

export default CheckBoxListInput;
