import api from "./api";
import TokenService from "./token.service";

const API_VERSION_V2 = "v2";

const login = async (email, password) => {
  try {
    const response = await api.post(
      "/auth/login",
      {
        email,
        password,
      },
      API_VERSION_V2
    );

    if (!response?.data?.user?.isAdmin && !response?.data?.user?.isSuperAdmin) {
      throw new Error(
        "You do not have admin privileges. Please contact the administrator for access."
      );
    }

    if (response.data.token) {
      TokenService.setUser(response.data);
    }

    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

const forgetpassword = async (email) => {
  const response = await api.post("/auth/forgot-password", {
    email,
  });

  return response;
};

const securityToken = async (token) => {
  const response = await api.post("/auth/verify-password-token", {
    token,
  });

  return response;
};

const resetPassword = async (email, token, password) => {
  const response = await api.post("/auth/reset-password", {
    email,
    password,
    token,
  });
  return response;
  // if (response.data.token) {
  //   TokenService.setUser(response.data);
  // }
  // return response.data;
};

const permissions = async (id) => {
  const response = await api.get("user-permission/" + id);
  if (response.data) {
    TokenService.setPermissions(response.data.data);
  }
  return response;
};

const logout = () => {
  TokenService.removeUser();
};

const getCurrentUser = () => JSON.parse(sessionStorage.getItem("user"));

const AuthService = {
  login,
  logout,
  getCurrentUser,
  forgetpassword,
  securityToken,
  resetPassword,
  permissions,
};

export default AuthService;
