import {
  SET_FULL_NAME,
  SET_ADDRESS,
  SET_DISTRICT,
  SET_PHONE,
  SET_INCHARGE,
  SET_LANGUAGE,
  SET_CONTENT_SPECIES,
  SET_SPECIES,
} from "./type";
import api from "../../services/api";
import { trackPromise } from "react-promise-tracker";
import { CONTENT_SPECIES } from "../../constants/apiUrls";

export const setAddress = (address) => async (dispatch) => {
  dispatch({
    type: SET_ADDRESS,
    payload: address,
  });
};

export const setFullName = (name) => async (dispatch) => {
  dispatch({
    type: SET_FULL_NAME,
    payload: name,
  });
};

export const setLanguage = (lang) => async (dispatch) => {
  dispatch({
    type: SET_LANGUAGE,
    payload: lang,
  });
};

export const setDistrict = (district) => async (dispatch) => {
  dispatch({
    type: SET_DISTRICT,
    payload: district,
  });
};

export const setSpecies = (species) => async (dispatch) => {
  dispatch({
    type: SET_SPECIES,
    payload: species,
  });
};
export const setPhone = (phone) => async (dispatch) => {
  dispatch({
    type: SET_PHONE,
    payload: phone,
  });
};

export const setIncharge = (incharge) => async (dispatch) => {
  dispatch({
    type: SET_INCHARGE,
    payload: incharge,
  });
};

export const getContentSpecies = (language) => async (dispatch) => {
  try {
    const response = await trackPromise(
      api.get(`${CONTENT_SPECIES}`, {}, "v2")
    );
    dispatch({
      type: SET_CONTENT_SPECIES,
      payload: response.data.data,
    });
    return Promise.resolve(response.data.data);
  } catch (error) {
    // dispatch({
    //   type: FETCH_FAQ_BY_ID_FAILURE,
    //   payload: error.message,
    // });
    // return Promise.reject(response.data.data);
  }
};
