import React, { useEffect, useState } from "react";
import {
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { WithHeader } from "../../../../component/common/WithHeader";
import { updatePermissionIds as updatePermissions } from "../../../../store/actions/users";
import { useDispatch } from "react-redux";

const NestedListWithParentCheckboxes = ({
  isEdit,
  menuList,
  selectedItems,
}) => {
  const dispatch = useDispatch();

  const [openItems, setOpenItems] = useState({});
  const [collapseItems, setCollapseItems] = useState({});
  const [checkedItems, setCheckedItems] = useState({});
  const [permissionIds, setPermissionIds] = useState([]);
  const [isFirst, setIsFirst] = useState(true);
  // Initialize checked items from selectedItems
  useEffect(() => {
    if (isFirst && isEdit && selectedItems?.length > 0) {
      setPermissionIds(selectedItems);

      const initialChecked = {};
      selectedItems.forEach((item) => {
        menuList.forEach((menu, menuIndex) => {
          if (menu.id === item.id) {
            initialChecked[menuIndex] = {
              parent: true,
              children: menu.child?.map(() =>
                selectedItems.some((child) => child.id === menu.id)
              ),
            };
          }
          menu.child?.forEach((child, childIndex) => {
            if (child.id === item.id) {
              initialChecked[menuIndex] = initialChecked[menuIndex] || {
                parent: false,
                children: [],
              };
              initialChecked[menuIndex].children[childIndex] = true;
            }
          });
        });
      });
      setCheckedItems(initialChecked);
      setIsFirst(false);
    }
  }, [selectedItems, menuList]);

  // Handle collapsing of menus
  const handleClick = (menuIndex) => {
    setOpenItems((prevState) => ({
      ...prevState,
      [menuIndex]: !prevState[menuIndex],
    }));
  };

  // Handle parent and child checkbox updates
  const handleCheckboxChange = (menuIndex, itemIndex = null) => {
    setCheckedItems((prevState) => {
      const updated = { ...prevState };
      const menuId = menuList[menuIndex].id;

      if (itemIndex === null) {
        // Toggle parent checkbox
        const isChecked = !updated[menuIndex]?.parent;

        // Update permissions
        updatePermissionIds(menuId, isChecked);

        // Update children
        if (menuList[menuIndex]?.child?.length > 0) {
          menuList[menuIndex].child.forEach((child, childIndex) => {
            updatePermissionIds(child.id, isChecked);
          });
        }

        updated[menuIndex] = {
          parent: isChecked,
          children: menuList[menuIndex]?.child?.map(() => isChecked) || [],
        };
      } else {
        // Toggle child checkbox
        const childId = menuList[menuIndex].child[itemIndex].id;
        const isChecked = !updated[menuIndex]?.children?.[itemIndex];

        // Update child permission
        updatePermissionIds(childId, isChecked);

        updated[menuIndex] = updated[menuIndex] || {
          parent: false,
          children: [],
        };
        updated[menuIndex].children[itemIndex] = isChecked;

        // Check if all children are selected
        updated[menuIndex].parent = updated[menuIndex].children.some(Boolean);

        // Update parent permission
        updatePermissionIds(menuId, updated[menuIndex].parent);
      }

      return updated;
    });
  };

  // Update Redux state for permissions
  const updatePermissionIds = (id, isSelected) => {
    setPermissionIds((prevState) => {
      if (isSelected) {
        return [...prevState, { id }].filter(
          (value, index, self) =>
            index === self.findIndex((item) => item.id === value.id)
        );
      } else {
        return prevState.filter((permission) => permission.id !== id);
      }
    });
  };

  useEffect(() => {}, []);

  useEffect(() => {
    dispatch(updatePermissions(permissionIds));
  }, [permissionIds, dispatch]);

  return (
    <List component="nav" aria-labelledby="nested-list-subheader">
      {menuList?.map((menu, menuIndex) => (
        <React.Fragment key={menu?.id}>
          <ListItemButton
            // disabled={isEdit}
            onClick={() => handleClick(menuIndex)}
          >
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ padding: "0px 9px" }}
                  checked={!!checkedItems[menuIndex]?.parent} // Check state for top-level
                  onChange={() => handleCheckboxChange(menuIndex)}
                  onClick={(e) => e.stopPropagation()} // Prevent event propagation to ListItemButton
                />
              }
              label={menu.permission}
            />
            {menu?.child?.length > 0 && (
              <>{openItems[menuIndex] ? <ExpandLess /> : <ExpandMore />}</>
            )}
          </ListItemButton>
          {menu?.child?.length > 0 && (
            <Collapse in={openItems[menuIndex]} timeout="auto" unmountOnExit>
              {menu.child.map((item, itemIndex) => (
                <List component="div" disablePadding key={item?.id}>
                  <ListItemButton sx={{ pl: 4 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{ padding: "0px 9px" }}
                          checked={
                            !!checkedItems[menuIndex]?.children?.[itemIndex] // Check state for nested items
                          }
                          onChange={() =>
                            handleCheckboxChange(menuIndex, itemIndex)
                          }
                        />
                      }
                      label={item.permission}
                    />
                  </ListItemButton>
                </List>
              ))}
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

export default WithHeader(NestedListWithParentCheckboxes, "title");
