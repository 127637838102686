export const SCORECARD_ROWS = [
  {
    id: 1,
    pointsType: "Articles",
    type: "Article",
    points: 20,
    action: 25,
    error: "",
  },
  {
    id: 2,
    pointsType: "Videos",
    type: "Video",
    points: 30,
    action: 32,
    error: "",
  },
  {
    id: 3,
    pointsType: "Slidecasts",
    type: "Training",
    points: 40,
    action: 45,
    error: "",
  },
  {
    id: 4,
    pointsType: "Referrals",
    type: "Referal",
    points: 50,
    action: 45,
    error: "",
  },
];

export const PREMIUM_SCORECARD_ROWS = [
  {
    id: 1,
    pointsType: "Articles",
    type: "ArticleRedeem",
    points: 20,
    action: 25,
    error: "",
  },
  {
    id: 2,
    pointsType: "Videos",
    type: "VideoRedeem",
    points: 30,
    action: 32,
    error: "",
  },
  {
    id: 3,
    pointsType: "Slidecasts",
    type: "TrainingRedeem",
    points: 40,
    action: 45,
    error: "",
  },
];
