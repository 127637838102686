import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  setCoverImage,
  setHtmlImage,
} from "../../../store/actions/currentArticle";
import CategorySelector from "../../common/CategorySelector";
import Content from "../../common/Content";
import CoverImage from "../../common/CoverImage";
import ReadingTime from "../../common/ReadingTime";
// import Score from '../../common/Score';
import Header from "../../common/Header";
import ResourceButton from "../../common/ResourceButton";
import AuthorDetail from "./AuthorDetail";
import {
  retrieveRescourcesbyId,
  clearCurrentRescource,
  setLearningResource,
  setLanguage,
  setContent,
} from "../../../store/actions/currentArticle";
import Recommendation from "../../common/Recommendation";
import { localizedContentSelector } from "../../../store/reducers/selector";
import { URL } from "../../../constants/URL";
import { getRequiredAspectRatio } from "./Helper";
import {
  retrieveLearningRecommendation,
  retrieveLearningResource,
} from "../../../store/actions/learningResource";
import { Box } from "@mui/material";

const TOLERANCE = 0; // Define a small tolerance value
const recommendedSizeMessage =
  "Best results with a 16:9 image, like 640x360 or 960x540.";

const NewArticle = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.article?.language);
  const [error, setError] = useState(false);

  const localizedContent = useSelector(localizedContentSelector);
  const requiredAspectRatio = getRequiredAspectRatio();

  const article = useSelector((state) => state.article);
  const { learningResource } = useSelector((state) => state.learningResource);
  const resource = learningResource.filter(
    (resource) => resource.learningResourceType === article.learningResourceType
  );

  const selectedCoverImage = useSelector(
    (state) => state?.article?.coverImage && state.article?.coverImage[lang]
  );

  const currentResourceType = useSelector((state) => state.resourceType);

  //oldCode from develop

  const { id } = useParams();
  useEffect(() => {
    dispatch(retrieveLearningRecommendation());

    if (id) {
      dispatch(retrieveRescourcesbyId(id));
    } else {
      dispatch(clearCurrentRescource());
      dispatch(setLearningResource("Article"));
      dispatch(setLanguage("EN"));
    }
  }, [dispatch, id]);

  return (
    <>
      <Header
        setLanguage={setLanguage}
        data={resource}
        error={error}
        setError={setError}
      />

      <Box sx={{ mb: 2 }}>
        <Content
          localizedContent={localizedContent}
          setContent={setContent}
          setHtmlImage={setHtmlImage}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <CoverImage
          setCoverImage={setCoverImage}
          requiredAspectRatio={requiredAspectRatio}
          recommendedSize={recommendedSizeMessage}
          selectedCoverImage={selectedCoverImage}
          TOLERANCE={TOLERANCE}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <CategorySelector />
      </Box>

      <Box sx={{ mb: 2 }}>
        <AuthorDetail />
      </Box>

      <Box sx={{ mb: 2 }}>
        <ReadingTime />
      </Box>
      {/* <Score /> */}

      <Box sx={{ mb: 2 }}>
        <Recommendation resource={"Article"} lang={lang} />
      </Box>
      {/* <ResourceButton
        addResourceUrl={URL.RESOURCE}
        ListingUrl={URL.LEARNING_RESOURCE_LISTING}
        error={error}
      /> */}
    </>
  );
};

export default NewArticle;
