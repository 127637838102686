import {
  Box,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Stack,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setRecommendation } from "../../store/actions/currentArticle";
import SelectionLimitDialog from "./SelectionLimitDialog";
import { WithHeader } from "./WithHeader";
import { Button } from "@mui/material";
import { RECOMMENDATION_FILTERS } from "../../constants/GeneralConstant";

const Recommendation = ({
  resource,
  lang,
  showRecommendationFilter = true,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [filteredRecommendations, setFilteredRecommendations] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([resource]);
  const selectedRecommendations = useSelector(
    (state) => state.article.recommendationsIds
  );
  let selectedRecommendationIds = selectedRecommendations.map((r) => r.id);

  const { recommendations } = useSelector((state) => state.learningResource);

  // useEffect to update recommendationFilters only when dependencies change
  useEffect(() => {
    let filteredResources = recommendations?.filter(
      (article) =>
        selectedFilters.includes(article.learningResourceType) &&
        article.id !== id
    );

    if (selectedFilters.length === 0) {
      filteredResources = recommendations?.filter(
        (article) => article.id !== id
      );
    }
    setFilteredRecommendations(filteredResources);
  }, [recommendations, selectedFilters, id]);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleToggle = (id, recommendationTitle) => () => {
    const currentIndex = selected.indexOf(recommendationTitle);
    const isSelected = selectedRecommendationIds.includes(id);

    if (selectedRecommendationIds.length === 5 && !isSelected) {
      setDialogOpen(true);
      return;
    }
    const newSelected = [...selected];

    if (currentIndex === -1) {
      newSelected.push(
        recommendations.find(
          (item) => item?.title?.[lang]
          // item?.title?.[LANGUAGE_KEY.LUGANDA] ||
          // item?.title?.[LANGUAGE_KEY.RUNYAKITARA] === recommendationTitle
        ).title
      );
    } else {
      newSelected.splice(currentIndex, 1);
    }
    setSelected(newSelected);
    dispatch(setRecommendation(id));
  };

  const getRecommendationRow = (item) => {
    const title = item?.title?.[lang];
    if (!title) return;

    return (
      <>
        <Stack
          key={item.id}
          alignItems="center"
          direction="row"
          justifyContent="flex-start"
        >
          <FormControlLabel
            value={item.id}
            sx={{
              m: 0,
            }}
            control={
              <Checkbox
                checked={selectedRecommendationIds.includes(item.id)}
                onChange={handleToggle(item.id, title)}
              />
            }
          />
          <CardHeader
            sx={{
              p: 0,
            }}
            titleTypographyProps={{
              fontSize: "14px", // Adjusts the font size of the MuiTypography-root
            }}
            key={item.id}
            title={title}
          />
        </Stack>
      </>
    );
  };

  return (
    <>
      {showRecommendationFilter && (
        <Box
          sx={{
            display: "flex",
            gap: 1,
            p: 2,
          }}
        >
          {RECOMMENDATION_FILTERS.map((item) => {
            if (item.type !== "Gallery") {
              const isSelected = selectedFilters.includes(item.type);

              return (
                <Button
                  type="primary"
                  size="sm"
                  sx={{
                    // backgroundColor: "#0B6273",
                    color: "#fff",
                    "&.Show-Selected-class": {
                      backgroundColor: "#0B6273", // Styles applied when this class is present
                    },
                    "&.show-unselected-class": {
                      backgroundColor: "#e1e1e1", // Styles for unselected class (optional)
                      color: '#777',
                    },
                  }}
                  className={
                    isSelected ? "Show-Selected-class" : "show-unselected-class"
                  }
                  onClick={() => {
                    setSelectedFilters(
                      (prevState) =>
                        isSelected
                          ? [
                              ...prevState.filter(
                                (filter) => filter !== item.type
                              ),
                            ] // Remove if already selected
                          : [...prevState, item.type] // Add if not selected
                    );
                  }}
                  key={item.type}
                >
                  {item.name}
                </Button>
              );
            }
          })}
        </Box>
      )}
      <Box className="cs-scroll" sx={{ overflowY: "auto", height: 300 }}>
        {filteredRecommendations?.length > 0 &&
          filteredRecommendations.map((item) => getRecommendationRow(item))}
      </Box>
      <SelectionLimitDialog
        data="Recommendation"
        count="5"
        open={dialogOpen}
        onClose={handleCloseDialog}
      ></SelectionLimitDialog>
    </>
  );
};

export default WithHeader(Recommendation, "Recommendations");
