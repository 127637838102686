import { LANGUAGE_KEY } from "../../constants/GeneralConstant";

//Takes values as input and returns localized object//
export function localizedObject(lang, value) {
  const data = { EN: "", LU: "", RY: "" };
  // Conditionally set the title properties based on the lang value

  switch (lang) {
    case LANGUAGE_KEY.ENGLISH:
      return { ...data, EN: value };
    case LANGUAGE_KEY.LUGANDA:
      return { ...data, LU: value };
    case LANGUAGE_KEY.RUNYAKITARA:
      return { ...data, RY: value };
    default:
      return;
  }
}

export const validateUrl = (regex) => (url) => regex.test(url);

export function filterTableRows(rows, searchQuery) {
  return rows.filter((item) => {
    const titleKeys = [
      LANGUAGE_KEY.ENGLISH,
      LANGUAGE_KEY.LUGANDA,
      LANGUAGE_KEY.RUNYAKITARA,
    ]; // Add other language keys if needed
    const searchQueryLowerCase = searchQuery?.toLowerCase();

    const matchedTitle = titleKeys.some((key) => {
      const title = item?.title?.[key]
        ? item?.title?.[key]?.toLowerCase()
        : null;
      return title && title.includes(searchQueryLowerCase);
    });

    const matchedCategory = item?.categories?.some((category) =>
      titleKeys.some((key) => {
        const categoryTitle = category?.title?.[key]
          ? category?.title?.[key]?.toLowerCase()
          : null;
        return categoryTitle && categoryTitle.includes(searchQueryLowerCase);
      })
    );

    return matchedTitle || matchedCategory;
  });
}

export function getFormLanguage(data) {
  // Check if all keys have data
  if (
    Object.values(data).every(
      (value) => value !== "" && value !== null && value !== undefined
    )
  ) {
    return LANGUAGE_KEY.ENGLISH; // Return English if all keys have data
  } else {
    // Check which key has data and return its language
    for (let key in data) {
      if (data[key] !== "" && data[key] !== null && data[key] !== undefined) {
        return key;
      }
    }
  }
}

export const prepareFaqPayload = (data, id = null) => {
  const payload = { ...data };
  payload["isActive"] = true;
  payload["categoryIds"] = payload["categoryIds"]?.map((t) => {
    return { id: t.id };
  });
  delete payload.categories;
  delete payload.createdBy;
  delete payload.updatedBy;
  return payload;
};

export const formatEmailSameLength = (emailString) => {
  if (!emailString.length) return "";
  const splitEmail = emailString.split("@");
  const domain = splitEmail[1];
  const name = splitEmail[0];
  return name
    .substring(0, 3)
    .concat(Array(name.length - 3).join("*"))
    .concat("@")
    .concat(domain);
};

export const getStatusClass = (status) => {
  if (status === "Published") {
    return "status-pill active";
  } else if (status === "Draft") {
    return "status-pill draft";
  }
  else {
    return "status-pill archive";
  }
};
