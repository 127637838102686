/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ListingRecord } from "../../../store/actions/learningResource";
import {
  clearFilter,
  setFilteredQueryParams,
} from "../../../store/actions/filter";
import { toast } from "react-toastify";
import { getEventsData } from "../../../store/actions/event";
import { getProducts } from "../../../store/actions/product";
import FilterBoxModel from "./FilterBoxModel";
import { URL } from "../../../constants/URL";
import { getAllAdmins } from "../../../store/actions/users";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  borderRadius: "5px",
};

const FilterBox = ({ handleClose, open }) => {
  const dispatch = useDispatch();
  const [createDateError, setCreateDateError] = useState("");
  const [updateDateError, setUpdateDateError] = useState("");
  

  const { faqs } = useSelector((state) => state.faqs);
  const { params: filterParams } = useSelector((state) => state.filter);

  const [filteredData, setFilteredData] = useState(filterParams);

  const HandleClearAll = () => {
    setFilteredData({});
    // dispatch(setFilteredQueryParams(''));
    if (filteredData.type === "Events") {
      dispatch(getEventsData());
    } else if (filteredData.type === "Products") {
      dispatch(getProducts());
    } else if (filteredData.type === "Faqs") {
      const params = {
        ...filterParams,
        page: 1,
        perPage: 20,
      };
      dispatch(getAllAdmins(params));
      dispatch(setFilteredQueryParams({}));
    } else {
      dispatch(ListingRecord(URL.LEARNING_RESOURCE_LISTING));
    }
    dispatch(clearFilter());
    handleClose();
  };

  const handleApply = () => {
    const filteredDataCopy = { ...filteredData };

    if (createDateError || updateDateError) {
      const errorMessage = createDateError ? createDateError : updateDateError;
      toast.error(errorMessage);
    } else {
      let data = {
        ...filterParams,
        createdAtStartDate: filteredDataCopy.startCreateDate,
        createdAtEndDate: filteredDataCopy.endCreateDate,
        updatedAtStartDate: filteredDataCopy.startUpdateDate,
        updatedAtEndDate: filteredDataCopy.endUpdateDate,
      };
      setFilteredData(data);
      dispatch(getAllAdmins(data));
      dispatch(setFilteredQueryParams(data));
      handleClose();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FilterBoxModel
            handleClose={handleClose}
            filteredData={filterParams}
            setFilteredData={setFilteredData}
            setCreateDateError={setCreateDateError}
            setUpdateDateError={setUpdateDateError}
            handleApply={handleApply}
            HandleClearAll={HandleClearAll}
          />
        </Box>
      </Modal>
    </>
  );
};

export default FilterBox;
