import React, { useEffect, useState } from "react";
// import Score from '../../common/Score';
import Header from "../../common/Header";
import CategorySelector from "../../common/CategorySelector";
import Recommendation from "../../common/Recommendation";
import VideoUpload from "../../common/VideoUpload";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";
import {
  retrieveRescourcesbyId,
  clearCurrentRescource,
  setLearningResource,
  setLanguage,
} from "../../../store/actions/currentArticle";
import ResourceButton from "../../common/ResourceButton";
import { URL } from "../../../constants/URL";
import { useSelector } from "react-redux";
import VideoUrlField from "../../common/UrlField";
import AuthorDetail from "../../articles/create-article/AuthorDetail";
import PublisherDetails from "../../common/publisherDetails";
import {
  retrieveLearningRecommendation,
  retrieveLearningResource,
} from "../../../store/actions/learningResource";

const RECOMMENDED_ASPECT_RATIO = 2 / 3;
const TOLERANCE = 0.05;
const recommendedSize =
  "Best results with a 2:3 image, like (400 x 600, 600 x 900)";
const Video = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [error, setError] = useState(false);
  const lang = useSelector((state) => state.article?.language);
  const article = useSelector((state) => state.article);
  const { learningResource } = useSelector((state) => state.learningResource);
  const resource = learningResource.filter(
    (resource) => resource.learningResourceType === article.learningResourceType
  );

  useEffect(() => {
    dispatch(retrieveLearningRecommendation());

    if (id) {
      dispatch(retrieveRescourcesbyId(id));
    } else {
      dispatch(clearCurrentRescource());
      dispatch(setLearningResource("Video"));
    }
  }, [dispatch, id]);

  return (
    <>
      <Header
        setLanguage={setLanguage}
        data={resource}
        error={error}
        setError={setError}
      />

      <Box sx={{ mb: 2 }}>
        <VideoUpload
          requiredAspectRatio={RECOMMENDED_ASPECT_RATIO}
          TOLERANCE={TOLERANCE}
          recommendedSize={recommendedSize}
        />
      </Box>

      <Typography variant="h6" sx={{ textAlign: "center", padding: "16px" }}>
        OR
      </Typography>

      <Box sx={{ mb: 2 }}>
        <VideoUrlField />
      </Box>

      <Box sx={{ mb: 2 }}>
        <CategorySelector />
      </Box>

      <Box sx={{ mb: 2 }}>
        <PublisherDetails></PublisherDetails>
      </Box>

      {/* <Score /> */}

      <Box sx={{ mb: 2 }}>
        <Recommendation resource={"Video"} lang={lang} />
      </Box>
      {/* <ResourceButton
        addResourceUrl={URL.RESOURCE}
        ListingUrl={URL.LEARNING_RESOURCE_LISTING}
        error={error}
      /> */}
    </>
  );
};

export default Video;
