import React from "react";
import {
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { FieldHeader } from "../Header";

const UrlInputField = ({
  name,
  value,
  onChange,
  error,
  helperText,
  title,
  placeholder,
  ...props
}) => {
  const handleVideoUrlChange = (event) => {
    const trimmedText = event.target.value.trimStart(); // Remove only leading spaces
    onChange({ target: { name, value: trimmedText } });
  };

  return (
    <>
      <FieldHeader headerLabel={title} />
      <FormControl fullWidth error={Boolean(error)}>
        <TextField
          name={name}
          value={value}
          onChange={handleVideoUrlChange}
          placeholder={placeholder}
          variant="outlined"
          {...props}
          sx={{
            borderRadius: 0,
            border: '1px solid #d9d9d9',
            p: 1.5,
          }}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </>
  );
};

export default UrlInputField;
