import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useParams } from "react-router-dom";
import { clearCurrentRescource } from "../../../store/actions/currentArticle";
import { ListingRecord } from "../../../store/actions/learningResource";
import FilterBox from "../../Filter/FilterBox";
import { URL } from "../../../constants/URL";
import LearningResourceTable from "../../common/LearningResourceTable";
import { retrieveLearningResource } from "../../../store/actions/learningResource";
import KYTListTable from "./KYTListTable";

const KYTList = () => {
  const { learningResource } = useSelector((state) => state.learningResource);
  const [filterBoxOpen, setFilterBoxOpen] = useState(false);
  const currentResourceType = useSelector((state) => state.resourceType);

  const handleFilterBoxOpen = () => {
    setFilterBoxOpen(true);
  };

  const handleFilterBoxClose = () => {
    setFilterBoxOpen(false);
  };

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(retrieveLearningResource({ type: "Tick" }));
    if (id === undefined) {
      dispatch(clearCurrentRescource());
    }
  }, [dispatch, id]);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   dispatch(ListingRecord(URL.LEARNING_RESOURCE_LISTING, currentResourceType));
  //   if (id === undefined) {
  //     dispatch(clearCurrentRescource());
  //   }
  // }, [dispatch, id, currentResourceType]);

  const [searchQuery, setSearchQuery] = useState("");
  const rows = learningResource;
  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleClearSearchQuery = (event) => {
    setSearchQuery("");
  };

  const filteredData = rows?.filter((item) => {
    const title = item?.title;
    if (typeof title === "string") {
      const searchQueryLowerCase = searchQuery?.toLowerCase();
      const lowercaseTitle = title.toLowerCase();

      return lowercaseTitle.includes(searchQueryLowerCase);
    }

    return false;
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          mb: 1.5,
          pt: 0.75,
          pb: 2,
          borderBottom: "1px solid #E1E1E1",
        }}
      >
        <Typography variant="h5">
          Know Your Ticks
          {!!filteredData?.length && (
            <sup style={{ fontSize: "0.6em", marginLeft: "4px" }}>
              ({filteredData.length})
            </sup>
          )}
        </Typography>
        <Box
          sx={{
            display: "flex",
            marginLeft: "auto",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <TextField
            placeholder="Search by Predilection"
            className="searchBar"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon color="action" fontSize="small">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
              endAdornment: searchQuery ? (
                <InputAdornment position="end">
                  <IconButton onClick={handleClearSearchQuery}>
                    <ClearIcon color="action" fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearchQueryChange}
          />
          <FilterAltOutlinedIcon
            sx={{
              cursor: "pointer",
              fontSize: "40px",
              border: "1px solid #E1E1E1",
              padding: "5px",
              borderRadius: "8px",
              color: "#rgb(51 51 51 / 73%)",
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor: "#118197",
                color: "#fff",
              },
              "&.selected-filter": {
                backgroundColor: "#118197", // Applied when class is active
                color: "#fff",
              },
            }}
            onClick={handleFilterBoxOpen}
          />
          <FilterBox open={filterBoxOpen} handleClose={handleFilterBoxClose} />
        </Box>
      </Box>
      <KYTListTable data={filteredData} />
    </>
  );
};

export default KYTList;
