import React, { useEffect, useState } from "react";
import { WithHeader } from "../common/WithHeader";
import { useDispatch, useSelector } from "react-redux";
import { createOrganizor, getOrganizor } from "../../store/actions/organizor";
import { getFilteredOrganizor } from "../../store/reducers/selector";
import {
  Button,
  CardHeader,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Box
} from "@mui/material";
import { Stack } from "@mui/system";
import { setOrganizorId } from "../../store/actions/currentEvent";
import { LANGUAGE_KEY } from "../../constants/GeneralConstant";

const Organizer = () => {
  const dispatch = useDispatch();
  const [organizorfullName, setOrganizorFullName] = useState("");
  const [organizorDetail, setOrganizorDetail] = useState("");
  const [errorFullname, setErrorFullname] = useState(false);
  const [errorDetails, setErrorDetails] = useState(false);
  const selectedOrganizor = useSelector(
    (state) => state.currentEvent.organizerId.id
  );
  const filteredOrganizor = useSelector(getFilteredOrganizor);
  const languageSelector = useSelector((state) => state.currentEvent.language);
  const authorUpdated = useSelector(
    (state) => state.organizorData.organizorUpdated
  );

  useEffect(() => {
    dispatch(getOrganizor());
  }, [dispatch, authorUpdated]);

  const handleSaveButton = () => {
    if (!organizorfullName.trim()) {
      setErrorFullname(true);
      return;
    }
    if (!organizorDetail.trim()) {
      setErrorDetails(true);
      return;
    }
    const org = {
      fullname: {
        [LANGUAGE_KEY.ENGLISH]: organizorfullName.trim(),
        [LANGUAGE_KEY.LUGANDA]: organizorfullName.trim(),
        [LANGUAGE_KEY.RUNYAKITARA]: organizorfullName.trim(),
      },
      details: {
        [LANGUAGE_KEY.ENGLISH]: organizorDetail.trim(),
        [LANGUAGE_KEY.LUGANDA]: organizorDetail.trim(),
        [LANGUAGE_KEY.RUNYAKITARA]: organizorDetail.trim(),
      },
    };
    dispatch(createOrganizor(org));
    setOrganizorFullName("");
    setOrganizorDetail("");
  };
  const handleOrganizorChange = (event) => {
    const optionId = event.target.value;
    dispatch(setOrganizorId(optionId));
  };

  const getAuthorRow = (item) => {
    const fullname = item.fullname[languageSelector];
    const details = item.details[languageSelector];

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack
          key={item.id}
          alignItems="center"
          direction="row"
          sx={{p: '4px 0'}}
        >
          <FormControlLabel
            sx={{
              m: 0,
            }}
            key={`${item.id}-FormControlLabel`}
            value={item.id}
            control={<Radio key={item.id} />}
          />
          <CardHeader sx={{ 
            p: 0,
            ml: 1,
          }} 
          titleTypographyProps={{
            fontSize: '14px', // Adjusts the font size of the MuiTypography-root
          }} title={fullname} key={`${item.id}-CardHeader`} />
          <a style={{fontSize: '14px', marginLeft: '5px'}} href={`${details}`}>{details}</a>
        </Stack>
      </Box>
    );
  };
  return (
    <>
      <RadioGroup value={selectedOrganizor} onChange={handleOrganizorChange}>
        {filteredOrganizor.length > 0 &&
          filteredOrganizor.map((item) => getAuthorRow(item))}
      </RadioGroup>
      <Box sx={{ display: "flex", p: 2, gap: 1 }}>
        <TextField
          fullWidth
          type="text"
          value={organizorfullName}
          onChange={(e) => {
            setOrganizorFullName(e.target.value);
            setErrorFullname(false);
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              // borderRadius: 0, // set borderRadius to 0
              // width: "27em",
              // marginRight: "0.5em",
              // marginLeft: "0.2em",
            },
          }}
          placeholder="Enter the name of the organizer here..."
          error={errorFullname}
          helperText={errorFullname && "Fullname is required"}
        />
        <TextField
          fullWidth
          type="text"
          value={organizorDetail}
          onChange={(e) => {
            setOrganizorDetail(e.target.value);
            setErrorDetails(false);
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              // borderRadius: 0, // set borderRadius to 0
              // width: "27em",
              // marginRight: "0.5em",
            },
          }}
          placeholder="Enter the details or link to the organizer here.."
          error={errorDetails}
          helperText={errorDetails && "Details is required"}
        />
        <Button variant="contained" size="small" onClick={handleSaveButton}>
          Save
        </Button>
      </Box>
    </>
  );
};

export default WithHeader(Organizer, "Organizer *");
