import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentHeader from "../../common/ContentHeader";
import LearningResourceTable from "../../common/LearningResourceTable";
import { useParams } from "react-router-dom";
import { clearCurrentRescource } from "../../../store/actions/currentArticle";
import { ListingRecord } from "../../../store/actions/learningResource";
import { URL } from "../../../constants/URL";
import { retrieveLearningResource } from "../../../store/actions/learningResource";
import { filterTableRows } from "../../common/GeneralHelper";
const ArticleList = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const { learningResource } = useSelector((state) => state.learningResource);
  const currentResourceType = useSelector((state) => state.resourceType);

  //oldCode from develop
  useEffect(() => {
    window.scrollTo(0, 0);
    if (id === undefined) {
      dispatch(clearCurrentRescource());
    }
    dispatch(retrieveLearningResource({ type: "Article" }));
  }, [dispatch, id]);

  const rows = learningResource;
  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleClearSearchQuery = (event) => {
    setSearchQuery("");
  };

  const filteredData = filterTableRows(rows, searchQuery);

  return (
    <>
      <ContentHeader
        header="Articles"
        route="/new-article"
        searchQuery={searchQuery}
        onSearchQueryChange={handleSearchQueryChange}
        onClearSearchQuery={handleClearSearchQuery}
        searchPlaceholder="Search by Title, Category"
        isNearBy={false}
        count={filteredData?.length ?? 0}
      />
      <LearningResourceTable
        data={filteredData}
        resourceType="Article"
        isGating={true}
        isActivate={true}
        deleteURL={URL.RESOURCE}
        listService={ListingRecord}
        listURL={URL.LEARNING_RESOURCE_LISTING}
      />
    </>
  );
};

export default ArticleList;
