import React, { useEffect, Suspense } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import LazyLoader from "../component/layout/loader/LazyLoader";
import Dashboard from "../component/dashboard/dashboard";
import TokenService from "../services/token.service";

import AppRoutes from "../config/routes";

function AppRouter() {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const permissions = TokenService?.getPermissions?.() || [];

  // Permission relevant work
  const loggedIdData = TokenService.getUser();
  const { user } = loggedIdData || {};

  // console.log("🚀 ~ file: index.js:9 ~ AppRouter ~ isLoggedIn", isLoggedIn);

  const isPermitted = (slug) => {
    if (!slug || !!user?.isSuperAdmin) return true;
    return permissions?.some((el) => el.slug === slug);
  };

  return (
    <Suspense fallback={<LazyLoader />}>
      <Routes>
        {isLoggedIn && (!!user?.isSuperAdmin || permissions?.length > 0) ? (
          // ONLY AUTHENTICATED ROUTES AVAILABLE IF LOGGED IN
          <Route
            path="/"
            element={<Dashboard user={user} isPermitted={isPermitted} />}
          >
            {AppRoutes.filter(
              (route) =>
                route.requireAuthentication === true && isPermitted(route?.slug)
            ).map((route, index) => (
              <Route
                key={`auth-route-${index}`}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>
        ) : (
          // ONLY NON-AUTHENTICATED ROUTES AVAILABLE IF LOGGED OUT
          AppRoutes.filter((route) => !route.requireAuthentication).map(
            (route, index) => (
              <Route
                key={`non-auth-route-${index}`}
                path={route.path}
                element={route.component}
              />
            )
          )
        )}
        {/* Fallback Route */}
        <Route
          path="*"
          element={
            !isLoggedIn ? (
              <Navigate to="/login" />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
      </Routes>
    </Suspense>
  );
}

export default AppRouter;
