import {
  GET_LISTING_SUCCESS,
  GET_LISTING_FAIL,
  GET_LISTING_REQUEST,
  DELETE_RECORD_SUCCESS,
  DELETE_RECORD_FAIL,
  TOGGLE_GATING_CONTENT_SUCCESS,
  TOGGLE_GATING_CONTENT_FAILURE,
  TOGGLE_ACTIVATION_CONTENT_FAILURE,
  TOGGLE_ACTIVATION_CONTENT_SUCCESS,
  DELETE_LEARING_RESOURCE_SUCCESS,
  DELETE_LEARING_RESOURCE_FAILURE,
  EDIT_LEARNING_RESOURCE_FAIL,
  EDIT_LEARNING_RESOURCE_SUCCESS,
  CREATE_LEARNING_RESOURCE_FAIL,
  CREATE_LEARNING_RESOURCE_SUCCESS,
  TOGGLE_MARK_PREMIUM_SUCCESS,
  TOGGLE_MARK_PREMIUM_FAILURE,
  GET_RECOMMENDATION_SUCCESS,
} from "../actions/type";

const initialState = {
  learningResource: [],
  recommendations: [],
  loading: false,
  error: null,
};

function learningResourceReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        recommendations: action.payload,
        loading: false,
      };
    case GET_LISTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LISTING_SUCCESS:
      return {
        ...state,
        learningResource: [...action.payload],
        loading: false,
      };
    case GET_LISTING_FAIL:
      return { ...state, error: action.payload, loading: false };

    case DELETE_LEARING_RESOURCE_SUCCESS:
      // Filter out deleted article from state
      const updatedResource = state.learningResource.filter(
        (resource) => resource.id !== action.payload
      );
      return {
        ...state,
        learningResource: updatedResource,
        loading: false,
        error: null,
      };
    case DELETE_LEARING_RESOURCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TOGGLE_GATING_CONTENT_SUCCESS:
      return {
        ...state,
        learningResource: state.learningResource.map((resource) =>
          resource.id === action.payload.id ? action.payload : resource
        ),
      };
    case TOGGLE_GATING_CONTENT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case TOGGLE_ACTIVATION_CONTENT_SUCCESS:
      return {
        ...state,
        learningResource: state.learningResource.map((resource) =>
          resource.id === action.payload.id ? action.payload : resource
        ),
      };
    case TOGGLE_ACTIVATION_CONTENT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case TOGGLE_MARK_PREMIUM_SUCCESS:
      return {
        ...state,
        learningResource: state.learningResource.map((resource) =>
          resource.id === action.payload.id ? action.payload : resource
        ),
      };
    case TOGGLE_MARK_PREMIUM_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CREATE_LEARNING_RESOURCE_SUCCESS:
      return { learningResource: [...state.learningResource, action.payload] };
    case CREATE_LEARNING_RESOURCE_FAIL:
      return { ...state, error: action.payload, loading: false };
    case EDIT_LEARNING_RESOURCE_SUCCESS:
      return { learningResource: [...state.learningResource] };
    case EDIT_LEARNING_RESOURCE_FAIL:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
}

export default learningResourceReducer;
