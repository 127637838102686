import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";
import {
  Card,
  CardContent,
  Chip,
  Modal,
  Typography,
  Stack,
  IconButton,
  Paper,
} from "@mui/material";

import CoverImage from "./CoverImage";
import Title from "./Title";
import ArticleTags from "./ArticleTags";
import Content from "./Content";
import {
  languageSelector,
  localizedAuthor,
  localizedContentSelector,
  localizedCoverImage,
  localizedTitle,
} from "../../store/reducers/selector";
import Author from "./Author";
import ArticleRecommendation from "./ArticleRecommendation";
import VideoTags from "./VideoTags";
import Thumbnail from "./Thumbnail";
import VideoRecommendation from "./VideoRecommendation";

import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";

const style = {
  width: "375px", // iPhone 6/7/8 width
  height: "600px", // iPhone 6/7/8 height
  backgroundColor: "#f5f5f5", // light gray background
  borderRadius: "40px", // round corners
  boxShadow: "0 10px 20px rgba(0,0,0,0.3)", // subtle drop shadow
  overflow: "hidden", // hide content outside the div
  overflowY: "scroll", // enable vertical scrollbar
  position: "absolute", // use absolute position to center the box
  top: "50%", // position the top edge at the center of the viewport
  left: "50%", // position the left edge at the center of the viewport
  transform: "translate(-50%, -50%)", // move the box up and left by half its width and height
  '&::-webkit-scrollbar': {
    width: '8px', // Width of the scrollbar
    height: '8px', // Height of the scrollbar for horizontal scrolling
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f0f0f0', // Track color
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888', // Thumb color
    borderRadius: '4px', // Round corners of the thumb
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555', // Thumb color on hover
  },
};

const FaqPreview = ({ handleClose, open }) => {
  const { title, categories, categoryIds, description } = useSelector(
    (state) => state.faqs.formData
  );
  const localizedTitle = title["EN"] || title["LU"] || title["RY"];
  const localizedDesc =
    description["EN"] || description["LU"] || description["RY"];
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style }}>
        <Card sx={{ borderRadius: 5, marginBottom: "-25px" }}>
          <CardContent>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
            >
              <Typography variant="body1" style={{ verticalAlign: "center" }}>
                Was this conversation helpful?
              </Typography>
              {/* <Stack direction="row"> */}
              <span>
                <IconButton color="primary">
                  <ThumbUpOffAltIcon />
                </IconButton>
                <IconButton color="primary">
                  <ThumbDownOffAltIcon />
                </IconButton>
              </span>
              {/* </Stack> */}
            </Stack>
          </CardContent>
          <CardContent>
            {categoryIds?.map((cat, index) => (
              <Chip
                key={`cat-chip-${index}`}
                label={cat.title["EN"] || cat.title["LU"] || cat.title["RY"]}
              />
            ))}
          </CardContent>
          <CardContent>
            <Paper
              elevation={0}
              sx={{
                mt: 2,
                padding: 2,
                backgroundColor: "#F5F5F5",
                borderRadius: 2,
              }}
            >
              {" "}
              <Typography variant="body1">
                <strong>Answer:</strong>
                <Content content={localizedDesc} />
              </Typography>
            </Paper>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

export default FaqPreview;
