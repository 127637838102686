import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  CardHeader,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
} from "@mui/material";

import { toast } from "react-toastify";
import SelectionLimitDialog from "../../../../component/common/SelectionLimitDialog";
import {
  addFAQCategory,
  deleteFAQCategory,
  fetchFAQCategories,
  updateFaqCategoriesData,
} from "../../../../store/actions/faqCategories";
import CategoryManager from "./CategoryManager";
import ConfirmationDialog from "../../../../component/dialogs/ConfirmationDialog";

const DIALOG_TITLE = "Alert";
const DIALOG_CONTENT =
  "This category is associated with the other FAQs; please update their categories before proceeding with deletion.";

const Categories = ({ language, handleFieldChange, selected }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchFAQCategories());
  }, [language]);

  const faqsCategories = useSelector((state) => state.faqCategories.categories);

  const filteredFaqCategories = faqsCategories;

  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [generalErrorMessage, setGeneralErrorMessage] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setError(false); // Reset error on input change
  };

  const handleAddClick = () => {
    if (
      faqsCategories.some(
        (cat) =>
          cat?.title?.[language]?.length > 0 &&
          cat?.title?.[language]?.toLowerCase() === inputValue?.toLowerCase()
      )
    ) {
      setError(true);
    } else {
      //TODO: ADD CATEGORIES
      dispatch(addFAQCategory({ title: { [language]: inputValue } }))
        .then((res) => {
          toast.success("Category Added Successfully");

          dispatch(fetchFAQCategories());
        })
        .catch((e) => toast.error(e));
      setInputValue("");
      dispatch(fetchFAQCategories()); // Refresh categories after deletion
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleToggleCategory = (id, categoryTitle) => {
    const isSelected = selected?.find((s) => s.id == id);
    const selectedCount = selected?.length;

    if (selectedCount >= 5 && !isSelected) {
      setDialogOpen(true); // Show dialog if selection limit is reached
      return;
    }

    handleFieldChange("categoryIds", [
      ...selected,
      { id: id, title: categoryTitle },
    ]);
  };

  const handleDeleteCategory = async (id) => {
    dispatch(deleteFAQCategory(id))
      .then((res) => {
        dispatch(fetchFAQCategories());
        toast.success("Category Deleted Successfully");
      })
      .catch((e) => {
        setShowConfirmationDialog(true);
      }); // Ensures proper error handling
  };

  const renderRow = ({
    item,
    language,
    selectedIds,
    onToggle,
    onDelete,
    isDeletable = true,
  }) => {
    const categoryTitle = item.title?.[language];

    if (!categoryTitle) return null;

    return (
      <Stack
        key={item.id}
        alignItems="center"
        direction="row"
        justifyContent="flex-start"
      >
        {/* Checkbox with toggle handler */}
        <FormControlLabel
        sx={{
          m: 0,
        }}
          value={item.id}
          control={
            <Checkbox
              checked={selectedIds.includes(item.id)}
              onChange={() => onToggle(item.id, item.title)}
            />
          }
        />

        {/* Category title */}
        <CardHeader sx={{ 
            p: 0,
          }} 
          titleTypographyProps={{
            fontSize: '14px', // Adjusts the font size of the MuiTypography-root
          }}
           key={item.id} title={categoryTitle} />



        {/* Delete IconButton */}
        {isDeletable && (
          <IconButton
            onClick={() => onDelete(item.id)}
            aria-label="delete"
            size="small"
              sx={{
                ml: 'auto',
                mr: 2,
              }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 20 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.75 23.25C3.0625 23.25 2.47417 23.0054 1.985 22.5163C1.49583 22.0271 1.25083 21.4383 1.25 20.75V4.5H0V2H6.25V0.75H13.75V2H20V4.5H18.75V20.75C18.75 21.4375 18.5054 22.0263 18.0163 22.5163C17.5271 23.0063 16.9383 23.2508 16.25 23.25H3.75ZM16.25 4.5H3.75V20.75H16.25V4.5ZM6.25 18.25H8.75V7H6.25V18.25ZM11.25 18.25H13.75V7H11.25V18.25Z"
                fill="black"
                fillOpacity="0.5"
              />
            </svg>
          </IconButton>
        )}
      </Stack>
    );
  };

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  return (
    <>
      <ConfirmationDialog
        open={showConfirmationDialog}
        title={DIALOG_TITLE}
        content={DIALOG_CONTENT}
        onClose={() => {
          setShowConfirmationDialog(false);
        }}
      />
      <CategoryManager
        data={faqsCategories}
        filteredData={filteredFaqCategories}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        onAddClick={handleAddClick}
        error={error}
        errorMessage="Category already exists!"
        generalErrorMessage={generalErrorMessage}
        renderRow={(item) =>
          renderRow({
            item,
            language,
            selectedIds: selected?.flatMap((i) => i.id) ?? [],
            onToggle: handleToggleCategory,
            onDelete: handleDeleteCategory,
            isDeletable: filteredFaqCategories?.length > 1,
          })
        }
        dialogComponent={SelectionLimitDialog}
        dialogProps={{
          data: "Categories",
          count: "5",
          open: dialogOpen,
          onClose: handleCloseDialog,
        }}
      />
    </>
  );
};

export default Categories;
