import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentHeader from "../../common/ContentHeader";
import LearningResourceTable from "../../common/LearningResourceTable";
import { useParams } from "react-router-dom";
import { clearCurrentRescource } from "../../../store/actions/currentArticle";
import { viewRecord } from "../../../store/actions/learningResource";
import { URL } from "../../../constants/URL";
import { NEARBYHELP_BULKIMPORT_URL } from "../../../constants/apiUrls";
import NearByTable from "./NearbyTable";
import { toast } from "react-toastify";
import {
  clearCurrentNearBy,
  getNearByList,
  toggleContentGatingAll,
} from "../../../store/actions/nearBy";
import { clearCurrentEvent } from "../../../store/actions/currentEvent";
import { clearCurrentProduct } from "../../../store/actions/currentProduct";

const NearByList = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const { nearBy } = useSelector((state) => state.nearBy);
  const [selectedRows, setSelectedRows] = useState([]);
  // const [isAuthorized, setIsAuthorized] = useState(
  //   localStorage.getItem("isAuthorized") || false
  // );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id === undefined) {
      dispatch(clearCurrentProduct());
      dispatch(clearCurrentEvent());
      dispatch(clearCurrentRescource());
    }
    dispatch(getNearByList(URL.NEARBYHELP));
  }, [dispatch, id]);

  const rows = nearBy;
  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleClearSearchQuery = (event) => {
    setSearchQuery("");
  };

  const filteredData = Array.isArray(rows)
    ? rows.filter((item) => {
        const fullNameEN = item?.fullname?.EN;
        const fullNameLU = item?.fullname?.LU;
        const fullNameRY = item?.fullname?.RY;
        const searchQueryLowerCase = searchQuery?.toLowerCase();

        const matchedEN =
          fullNameEN && fullNameEN.toLowerCase().includes(searchQueryLowerCase);
        const matchedLU =
          fullNameLU && fullNameLU.toLowerCase().includes(searchQueryLowerCase);
        const matchedRY =
          fullNameRY && fullNameRY.toLowerCase().includes(searchQueryLowerCase);
        return matchedEN || matchedLU || matchedRY;
      })
    : [];

  const HandleGateToggleAll = (isAuthorized) => {
    // const nearby = nearBy?.find((obj) => obj.id === params.id);
    if (!selectedRows.length > 0) {
      toast.error(
        `Please select records first to ${
          isAuthorized ? "ungate" : "gate"
        } nearby`
      );
    } else {
      dispatch(
        toggleContentGatingAll(
          URL.TOGGLE_GATE_UNGATE,
          selectedRows,
          isAuthorized
        )
      )
        .then(() => {
          dispatch(getNearByList(URL.NEARBYHELP));
          if (isAuthorized) {
            toast.success("Successfully ungated all!");
          } else {
            toast.success("Successfully gated all!");
          }
        })
        .catch(() => {
          toast.error("Failed to gate/ungate nearby");
        });
    }
  };

  return (
    <>
      <ContentHeader
        header="Nearby Help"
        route="/new-NearByList"
        bulkImportRoute="/nearbyHelp/bulkImport"
        searchQuery={searchQuery}
        onSearchQueryChange={handleSearchQueryChange}
        HandleGateToggleAll={HandleGateToggleAll}
        onClearSearchQuery={handleClearSearchQuery}
        searchPlaceholder="Search by Name"
        isNearBy={true}
        count={filteredData?.length ?? 0}
      />
      <NearByTable
        data={filteredData}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      ></NearByTable>
    </>
  );
};

export default NearByList;
