// src/redux/thunks/faqThunks.js

import {
  ADD_FAQ_SUCCESS,
  DELETE_FAQ_SUCCESS,
  EDIT_FAQ_SUCCESS,
  FETCH_FAQ_BY_ID_FAILURE,
  FETCH_FAQ_BY_ID_REQUEST,
  FETCH_FAQ_BY_ID_SUCCESS,
  FETCH_FAQS_FAILURE,
  FETCH_FAQS_REQUEST,
  FETCH_FAQS_SUCCESS,
  RESET_FAQS_CATEGORY_FORM,
  RESET_FAQS_FORM,
  SET_FAQS_LANGUAGE,
  SET_LANGUAGE,
  TOGGLE_FAQ_ACTIVATION_FAILURE,
  TOGGLE_FAQ_ACTIVATION_SUCCESS,
  UPDATE_FAQS_FORM,
} from "./type";
import {
  FAQS_BY_ID,
  FAQS_STATUS_TOGGLE,
  FAQS_URL,
} from "../../constants/apiUrls";
import api from "../../services/api";
import { trackPromise } from "react-promise-tracker";

export const setLanguage = (lang) => async (dispatch) => {
  dispatch({
    type: SET_FAQS_LANGUAGE,
    payload: lang,
  });
};

export const updateFormData = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_FAQS_FORM,
    payload: data,
  });
};

export const resetFormData = () => async (dispatch) => {
  dispatch({
    type: RESET_FAQS_FORM,
    payload: {
      title: { EN: "", LU: "", RY: "" },
      description: { EN: "", LU: "", RY: "" },
      categoryIds: [],
      agreeCount: 0,
      disagreeCount: 0,
    },
  });
};

export const fetchFAQs = (params) => async (dispatch) => {
  dispatch({
    type: FETCH_FAQS_REQUEST,
  });
  try {
    const response = await trackPromise(
      api.post(`${FAQS_URL}/all`, params, "v2")
    );
    dispatch({
      type: FETCH_FAQS_SUCCESS,
      payload: response.data.data,
    });
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch({
      type: FETCH_FAQS_FAILURE,
      payload: error.message,
    });
    return Promise.reject(error);
  }
};

export const fetchFAQbyId = (faq) => async (dispatch) => {
  dispatch({
    type: FETCH_FAQ_BY_ID_REQUEST,
  });
  try {
    const response = await trackPromise(
      api.get(`${FAQS_URL}/${faq}`, {}, "v2")
    );
    dispatch({
      type: FETCH_FAQ_BY_ID_SUCCESS,
      payload: response.data.data,
    });
    return Promise.resolve(response.data.data);
  } catch (error) {
    dispatch({
      type: FETCH_FAQ_BY_ID_FAILURE,
      payload: error.message,
    });
    return Promise.reject(response.data.data);
  }
};

export const addFAQ = (faq) => async (dispatch) => {
  try {
    const response = await trackPromise(api.post(FAQS_URL, faq, "v2"));
    dispatch({
      type: ADD_FAQ_SUCCESS,
      payload: response.data,
    });
    return Promise.resolve(response.data);
  } catch (error) {
    console.error("Failed to add FAQ:", error);
    return Promise.reject(error);
  }
};

export const editFAQ = (faq) => async (dispatch) => {
  try {
    const id = faq.id;
    delete faq.id;
    const response = await trackPromise(
      api.patch(`${FAQS_URL}/${id}`, faq, "v2")
    );
    dispatch({
      type: EDIT_FAQ_SUCCESS,
      payload: response.data,
    });
    return Promise.resolve(response.data);
  } catch (error) {
    console.error("Failed to edit FAQ:", error);
    return Promise.reject(error);
  }
};

export const toggleFAQActivation =
  (id = null, resource) =>
  async (dispatch) => {
    const temp =
      resource.status === "Published"
        ? "Draft"
        : "Published" && resource.status !== "Published"
        ? "Published"
        : null;
    try {
      const res = await trackPromise(
        api.patch(
          FAQS_STATUS_TOGGLE.replace(":id", id).replace(":status", temp),
          {},
          "v2"
        )
      );
      dispatch({
        type: TOGGLE_FAQ_ACTIVATION_SUCCESS,
        payload: res.data.data,
      });
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch({
        type: TOGGLE_FAQ_ACTIVATION_FAILURE,
        payload: err.message,
      });
      return Promise.reject(err);
    }
  };

export const deleteFAQ = (id) => async (dispatch) => {
  try {
    const response = await trackPromise(
      api.delete(`${FAQS_URL}/${id}`, {}, "v2")
    );
    dispatch({
      type: DELETE_FAQ_SUCCESS,
      payload: response.data,
    });
    return Promise.resolve(response.data);
  } catch (error) {
    console.error("Failed to delete FAQ:", error);
    return Promise.reject(error);
  }
};
