import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  CardHeader,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Box
} from "@mui/material";
import { Stack } from "@mui/system";
import SelectionLimitDialog from "../../common/SelectionLimitDialog";
import { WithHeader } from "../../common/WithHeader";
import { getFilteredSpecies } from "../../../store/reducers/ProductSelector";
import {
  createSpecies,
  deleteSpecies,
  getSpecies,
} from "../../../store/actions/species";
import { setSpecies } from "../../../store/actions/currentProduct";
import { toast } from "react-toastify";
import ConfirmationDialog from "../../dialogs/ConfirmationDialog";

const DIALOG_TITLE = "Alert";
const DIALOG_CONTENT =
  "This species is associated with the other Products; please update the product before proceeding with deletion.";

const Species = () => {
  const dispatch = useDispatch();
  const [titleValue, setTitleValue] = useState("");
  const [error, setError] = useState(false);
  const [selected, setSelected] = useState([]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const filteredSpecies = useSelector(getFilteredSpecies);
  const languageSelector = useSelector(
    (state) => state.currentProduct.language
  );
  const selectedSpecies = useSelector(
    (state) => state.currentProduct.speciesIds
  );
  let selectedCategoriesId = selectedSpecies.flatMap((c) => c.id);

  const handleAddClick = () => {
    const existingCategory = filteredSpecies.find(
      (c) =>
        c.title[languageSelector].toLowerCase() === titleValue.toLowerCase()
    );

    if (existingCategory) {
      // Category already exists, do something
      setError(true);
      return;
    } else {
      // Category doesn't exist, create a new one
      dispatch(createSpecies(titleValue, languageSelector))
        .then(() => {
          dispatch(getSpecies());
        })
        .then(() => {
          setTitleValue("");
        });
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const handleToggleCategory = (id, categoryTitle) => () => {
    const currentIndex = selected.indexOf(categoryTitle);
    const selectedCount = selectedSpecies.length;
    const isCategorySelected = selectedCategoriesId.includes(id);

    if (selectedCount === 5 && !isCategorySelected) {
      setDialogOpen(true);
      return;
    }

    const newSelected = [...selected];
    if (currentIndex === -1) {
      newSelected.push(categoryTitle);
    } else {
      newSelected.splice(currentIndex, 1);
    }
    setSelected(newSelected);
    dispatch(setSpecies(id));
  };

  const handleDeleteSpecies = (id) => {
    dispatch(deleteSpecies(id))
      .then((res) => {
        toast.success("Species deleted Successfully");
        dispatch(getSpecies());
      })
      .catch((e) => {
        setShowConfirmationDialog(true);
      });
  };

  const getCategoryRow = (item) => {
    const categoryTitle = item.title[languageSelector];
    return (
      <Stack
        key={item.id}
        alignItems="center"
        direction="row"
        justifyContent="flex-start"
      >
        <FormControlLabel
          sx={{
            m: 0,
          }}
          value={item.id}
          control={
            <Checkbox
              checked={selectedCategoriesId.includes(item.id)}
              onChange={handleToggleCategory(item.id, item.title)}
            />
          }
        />
        <CardHeader
          sx={{ 
            p: 0,
          }} 
          titleTypographyProps={{
            fontSize: '14px', // Adjusts the font size of the MuiTypography-root
          }}
         key={item.id} 
         title={categoryTitle} 
        />

        {filteredSpecies?.length !== 1 && (
          <IconButton
            onClick={() => handleDeleteSpecies(item.id)}
            // disabled={filteredCategories?.length === 1}
            aria-label="delete"
            size="small"
              sx={{
                ml: 'auto',
                mr: 2,
              }}
            >
            <svg
              width="16"
              height="16"
              viewBox="0 0 20 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.75 23.25C3.0625 23.25 2.47417 23.0054 1.985 22.5163C1.49583 22.0271 1.25083 21.4383 1.25 20.75V4.5H0V2H6.25V0.75H13.75V2H20V4.5H18.75V20.75C18.75 21.4375 18.5054 22.0263 18.0163 22.5163C17.5271 23.0063 16.9383 23.2508 16.25 23.25H3.75ZM16.25 4.5H3.75V20.75H16.25V4.5ZM6.25 18.25H8.75V7H6.25V18.25ZM11.25 18.25H13.75V7H11.25V18.25Z"
                fill="black"
                fill-opacity="0.5"
              />
            </svg>
          </IconButton>
        )}
      </Stack>
    );
  };

  return (
    <>
      <ConfirmationDialog
        open={showConfirmationDialog}
        title={DIALOG_TITLE}
        content={DIALOG_CONTENT}
        onClose={() => {
          setShowConfirmationDialog(false);
        }}
      />
      {filteredSpecies.length > 0 &&
        filteredSpecies.map((item) => getCategoryRow(item))}
      <TextField
        fullWidth
        type="text"
        value={titleValue}
        onChange={(e) => {
          setTitleValue(e.target.value);
          setError(false);
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: 0, // set borderRadius to 0
          },
        }}
        placeholder="Start typing to create a new specie"
        InputProps={{
          endAdornment: titleValue && (
            <Button
              sx={{ width: '200px' }}
              variant="contained"
              onClick={handleAddClick}
              size="sm"
            >
              Add Category
            </Button>
          ),
        }}
        error={error}
        helperText={error && "Category already exists!"}
      />
      <SelectionLimitDialog
        data="Categories"
        open={dialogOpen}
        onClose={handleCloseDialog}
      />
    </>
  );
};
export default WithHeader(Species, "Species *");
