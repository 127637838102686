// src/redux/reducers/faqReducer.js
import {
  FETCH_USER_SUCCESS,
  FETCH_USER_REQUEST,
  FETCH_USER_FAILURE,
  UPDATE_STATUS_USER_REQUEST,
  UPDATE_STATUS_USER_SUCCESS,
  UPDATE_STATUS_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  UPDATE_USER_FORM,
  FETCH_USER_PERMISSIONS_REQUEST,
  FETCH_USER_PERMISSIONS_FAILURE,
  FETCH_USER_PERMISSIONS_SUCCESS,
  RESET_ADMIN_USER_FORM,
  SET_SELECTED_PERMISSIONS,
  USER_RESEND_PASSWORD_REQUEST,
  USER_RESEND_PASSWORD_SUCCESS,
  USER_RESEND_PASSWORD_FAILURE,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  RESEND_OTP_REQUEST,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_FAILURE,
} from "../actions/type";

const initialState = {
  formData: {
    firstname: "",
    lastname: "",
    email: "",
    permissions: [],
  },
  selectedLanguage: "EN",
  loading: false,
  list: {},
  error: null,
  permissionsList: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_FORM:
      return {
        ...state,
        formData: action.payload,
      };
    case RESET_ADMIN_USER_FORM:
      return {
        ...state,
        formData: {
          firstname: "",
          lastname: "",
          permissions: [],
        },
      };
    case SET_SELECTED_PERMISSIONS:
      return {
        ...state,
        formData: {
          ...state.formData,
          permissions: action.payload,
        },
      };
    case FETCH_USER_REQUEST:
      return { ...state, list: {}, loading: true };
    case FETCH_USER_SUCCESS:
      return { ...state, loading: false, list: action.payload };
    case FETCH_USER_FAILURE:
      return { ...state, loading: false, error: action.payload, list: {} };
    case UPDATE_STATUS_USER_REQUEST:
      return { ...state, loading: true };
    case UPDATE_STATUS_USER_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_STATUS_USER_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case DELETE_USER_REQUEST:
      return { ...state, loading: true };
    case DELETE_USER_SUCCESS:
      return { ...state, loading: false };
    case DELETE_USER_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_USER_PERMISSIONS_REQUEST:
      return { ...state, loading: true };
    case FETCH_USER_PERMISSIONS_SUCCESS:
      return { ...state, permissionsList: action.payload };
    case FETCH_USER_PERMISSIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case USER_RESEND_PASSWORD_REQUEST:
      return { ...state, loading: true };
    case USER_RESEND_PASSWORD_SUCCESS:
      return { ...state, loading: false };
    case USER_RESEND_PASSWORD_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case VERIFY_OTP_REQUEST:
      return { ...state, loading: true };
    case VERIFY_OTP_SUCCESS:
      return { ...state, loading: false };
    case VERIFY_OTP_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case RESEND_OTP_REQUEST:
      return { ...state, loading: true };
    case RESEND_OTP_SUCCESS:
      return { ...state, loading: false };
    case RESEND_OTP_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default userReducer;
