import { SET_DRAWER_OPEN, SET_DRAWER_CLOSE } from "./type";

export const setDrawerOpen = () => async (dispatch) => {
  dispatch({
    type: SET_DRAWER_OPEN,
    payload: true,
  });
};

export const setDrawerClose = () => async (dispatch) => {
  dispatch({
    type: SET_DRAWER_CLOSE,
    payload: false,
  });
};
