import React, { useState } from "react";
import { IconButton, Typography, Stack } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { WithHeader } from "../../../../component/common/WithHeader";

const Feedback = ({ faq }) => {
  const handleThumbsUp = () => {};

  const handleThumbsDown = () => {};

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {/* Thumbs Up */}
      <Stack direction="row" spacing={1} alignItems="center">
        <IconButton color="primary" onClick={handleThumbsUp}>
          <ThumbUpIcon />
        </IconButton>
        <Typography variant="body1">{faq.agreeCount || 0}</Typography>
      </Stack>

      {/* Thumbs Down */}
      <Stack direction="row" spacing={1} alignItems="center">
        <IconButton color="secondary" onClick={handleThumbsDown}>
          <ThumbDownIcon />
        </IconButton>
        <Typography variant="body1">{faq.disagreeCount || 0}</Typography>
      </Stack>
    </Stack>
  );
};
export default WithHeader(Feedback, "Feedback");
