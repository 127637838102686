import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  setCoverImage,
  setHtmlImage,
} from "../../../store/actions/currentArticle";
import CategorySelector from "../../common/CategorySelector";
import Content from "../../common/Content";
import CoverImage from "../../common/CoverImage";
// import Score from '../../common/Score';
import Header from "../../common/Header";
import {
  retrieveRescourcesbyId,
  clearCurrentRescource,
  setLearningResource,
  setLanguage,
  setContent,
} from "../../../store/actions/currentArticle";
import Recommendation from "../../common/Recommendation";
import { localizedContentSelector } from "../../../store/reducers/selector";
import ResourceButton from "../../common/ResourceButton";
import { URL } from "../../../constants/URL";
import { getRequiredAspectRatio } from "./Helper";
import AuthorDetail from "../../articles/create-article/AuthorDetail";
import {
  retrieveLearningRecommendation,
  retrieveLearningResource,
} from "../../../store/actions/learningResource";
import { Box } from "@mui/material";

const TOLERANCE = 0;
const recommendedSize =
  "Best results with a 16:9 image, like 640x360 or 960x540.";

const Gallery = () => {
  const [error, setError] = useState(false);

  const lang = useSelector((state) => state.article.language);
  const selectedCoverImage = useSelector(
    (state) => state.article.coverImage[lang]
  );
  const localizedContent = useSelector(localizedContentSelector);
  const requiredAspectRatio = getRequiredAspectRatio();

  const article = useSelector((state) => state.article);
  const { learningResource } = useSelector((state) => state.learningResource);
  const resource = learningResource.filter(
    (resource) => resource.learningResourceType === article.learningResourceType
  );

  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(retrieveLearningRecommendation());

    if (id) {
      dispatch(retrieveRescourcesbyId(id));
    } else {
      dispatch(clearCurrentRescource());
      dispatch(setLearningResource("Gallery"));
      dispatch(retrieveLearningResource({ type: "Gallery" }));
      dispatch(setLanguage("EN"));
    }
  }, [dispatch, id]);

  return (
    <>
      <Header
        setLanguage={setLanguage}
        data={resource}
        error={error}
        setError={setError}
      />

      <Box sx={{ mb: 2 }}>
        <Content
          localizedContent={localizedContent}
          setContent={setContent}
          setHtmlImage={setHtmlImage}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <CoverImage
          setCoverImage={setCoverImage}
          selectedCoverImage={selectedCoverImage}
          recommendedSize={recommendedSize}
          requiredAspectRatio={requiredAspectRatio}
          TOLERANCE={TOLERANCE}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <CategorySelector />
      </Box>

      {/* <Score /> */}

      <Box sx={{ mb: 2 }}>
        <Recommendation
          resource={"Gallery"}
          lang={lang}
          showRecommendationFilter={false}
        />
      </Box>
      {/* <ResourceButton
        addResourceUrl={URL.RESOURCE}
        ListingUrl={URL.LEARNING_RESOURCE_LISTING}
        error={error}
      /> */}
    </>
  );
};

export default Gallery;
