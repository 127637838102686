// external import
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  CircularProgress,
  Alert,
} from '@mui/material';
import { toast } from 'react-toastify';
import './style.scss';

// internal import
import FormValidation from '../common/FormValidations';
import { setpassword } from '../../store/actions/auth';
import Spinner from '../layout/Spinner';

function ResetPassword() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { message } = useSelector((state) => state.message);

  const email = location?.state?.data?.state?.email || '';
  const token = location?.state?.token || '';

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_password: '',
    },
    validationSchema: FormValidation.ResetPasswordSchema,
    onSubmit: (values, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      dispatch(setpassword(email, token, values.password))
        .then(() => {
          navigate('/login');
          toast.success(
            'Password has been successfully reset. Please use your new password to log in.'
          );
        })
        .catch(() => {
          setLoading(false);
        });
    },
  });

  return (
    <>
      <Box
        component="main"
        sx={{
          display: 'flex',
          minHeight: '100vh',
        }}
      >
        {/* Left: Reset Password Form */}
        <Container
          maxWidth="xs"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flex: 1,
            padding: 4,
          }}
        >
          <Box sx={{ px: 3 }}>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mb: 3 }}>
                <Box
                  component="img"
                  src="/img/elanco-logo.svg"
                  alt="Elanco"
                  sx={{
                    height: '80px',
                    objectFit: 'cover',
                    mb: 3,
                  }}
                />
                <Typography color="textPrimary" variant="h4" sx={{ mb: 1, fontSize: '1.5rem' }}>
                  Reset Password
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  Enter and confirm the new password for your account.
                </Typography>
              </Box>

              <TextField
                error={Boolean(
                  formik.touched.password && formik.errors.password
                )}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="password"
                value={formik.values.password}
                variant="outlined"
              />

              <TextField
                error={Boolean(
                  formik.touched.confirm_password &&
                  formik.errors.confirm_password
                )}
                fullWidth
                helperText={
                  formik.touched.confirm_password &&
                  formik.errors.confirm_password
                }
                label="Confirm Password"
                margin="normal"
                name="confirm_password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="password"
                value={formik.values.confirm_password}
                variant="outlined"
              />

              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={formik.isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {loading && (
                    <CircularProgress size={24} sx={{ marginRight: 1 }} />
                  )}
                  Reset Password
                </Button>

                {message && (
                  <div className="form-group" style={{ paddingTop: '1em' }}>
                    <Alert severity="error">{message}</Alert>
                  </div>
                )}
              </Box>
            </form>
          </Box>
        </Container>

        {/* Right: Image */}
        <Box
          className="hero-img"
          sx={{
            flex: 1,
            backgroundImage: 'url(/img/elanco-admin-cattle.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: { xs: 'none', md: 'block' },
          }}
        ></Box>
      </Box>
      <Spinner />
    </>
  );
}

export default ResetPassword;
