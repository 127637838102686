export const STATUS = {
  DRAFT: "Draft",
  PUBLISHED: "Published",
};

export const LEGALSTYPE = {
  TERM_AND_CONDITION: "TermsAndCondition",
  PRIVACYPOLICY: "PrivacyPolicy",
};

export const RESOURCE_TYPE = {
  ARTICLE: "Article",
  VIDEO: "Video",
};

export const NEAR_BY_FIELDS = {
  ADDRESS: "Address *",
  PHONE: "Phone *",
  INCHARGE: "Incharge *",
  FULL_NAME: "FullName *",
  DISTRICT: "District *",
};

export const LANGUAGE_KEY = {
  ENGLISH: "EN",
  LUGANDA: "LU",
  RUNYAKITARA: "RY",
};

export const LANGUAGE_TYPE = {
  ENGLISH: "English",
  LUGANDA: "LUGANDA",
  RUNYAKITARA: "Runyakitara",
};

export const LEARNING_RESOURCE_TYPES = {
  TICK: "Tick",
  ARTICLE: "Article",
  VIDEO: "Video",
  TRAINING: "Training",
  GALLERY: "Gallery",
};

export const RECOMMENDATION_FILTERS = [
  { type: "Article", name: "Article" },
  { type: "Video", name: "Video" },
  { type: "Training", name: "SlideCast" },
  { type: "Gallery", name: "Gallery" },
];

export const CONTENT_STATES = {
  SCHEDULED: "Scheduled",
  PUBLISH: "Publish",
  PUBLISHED: "Published",
  DRAFT: "Draft",
  ARCHIVED: "Archived",
};

export const RESOURCES = {
  ARTICLE: "Article",
  VIDEO: "Video",
  TRAINING: "Training",
  GALLERY: "Gallery",
};

export const URL_REGEX =
  /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[a-zA-Z0-9._~:\/?#[\]@!$&'()*+,;=-]*)?$/;
export const YT_REGEX =
  /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[A-Za-z0-9_-]{11}(&.*)?$/;
export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

//PAGINATION CONSTANTS
export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_PAGE_NUMBER = 1;

export const SESSION_STORAGE = {
  SPEC_LANGUAGE: "speciesLanguage",
};

export const CODE_ATTEMPT = "code-attempt";
