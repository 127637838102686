import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
  Box,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Content from "../common/Content";
import {
  AddPolicy,
  getPolicy,
  resetContentAndLanguage,
  setContent,
  setLanguage,
} from "../../store/actions/policy";
import { useDispatch, useSelector } from "react-redux";
import { localizedContentSelector } from "../../store/reducers/content-selector";
import ContentPreview from "../preview/ContentPreview";
import { toast } from "react-toastify";
import { LEGALSTYPE, STATUS } from "../../constants/GeneralConstant";

const TermAndCondition = () => {
  const [lang, setLang] = useState("EN");
  const dispatch = useDispatch();
  const localizedContent = useSelector(localizedContentSelector);
  const [previewOpen, setPreviewOpen] = useState(false);
  const content = useSelector((state) => state.content);

  const handleLanguage = (event) => {
    const language = event.target.value;
    setLang(language);
    dispatch(setLanguage(language));
  };

  const handlePreviewOpen = () => {
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  useEffect(() => {
    dispatch(resetContentAndLanguage());
    dispatch(getPolicy(LEGALSTYPE.TERM_AND_CONDITION));
  }, [dispatch]);

  const handleContent = (status) => {
    const data = {
      policy: content?.policy,
      status,
      legalsType: LEGALSTYPE.TERM_AND_CONDITION,
    };

    dispatch(AddPolicy(data))
      .then(() => {
        toast.success("Updated successfully");
      })
      .catch((error) => {
        toast.error("Failed to update data.");
      });
  };

  const handleDraftContent = () => {
    handleContent(STATUS.DRAFT);
  };

  const handlePublishContent = () => {
    handleContent(STATUS.PUBLISHED);
  };

  const getStatusClass = (status) => {
    switch (status) {
      case STATUS.PUBLISHED:
        return "status-publish";
      case STATUS.DRAFT:
        return "status-draft";
      default:
        return "";
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1.5,
          pt: 0.75,
          pb: 2,
          borderBottom: "1px solid #E1E1E1",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
          }}
        >
          <Typography variant="h5">{"Terms & Conditions"}</Typography>
          <FormControl
            size="small"
            className="selector"
            sx={{ minWidth: "10rem", marginLeft: 2 }}
          >
            <Select
              onChange={handleLanguage}
              value={lang}
              displayEmpty
              name="language"
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value={"EN"}>English</MenuItem>
              <MenuItem value={"LU"}>Luganda</MenuItem>
              <MenuItem value={"RY"}>Runyakitara</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box>
          <Typography
            variant="h5"
            sx={{
              fontSize: "15px",
              fontWeight: "400",
            }}
          >
            Status:{" "}
            <Chip
              label={content?.status}
              className={getStatusClass(content?.status)}
              sx={{
                fontSize: '15px',
                borderRadius: '6px',
                "&.status-publish": {
                  backgroundColor: "#3E9F5F1A",
                  color: "#3E9F5F",
                },
                "&.status-draft": {
                  backgroundColor: "#D4A7051A",
                  color: "#D9AE16",
                },
              }}
            />
          </Typography>
        </Box>
      </Box>

      <Content localizedContent={localizedContent} setContent={setContent} />
      <Box
        sx={{ 
          display: "flex", 
          justifyContent: "center",
          padding: "16px 0",
          gap: 1,
        }}
      >
        <ContentPreview open={previewOpen} handleClose={handlePreviewClose} />
        <Button
          size="small"
          variant="contained"
          onClick={handlePreviewOpen}
        >
          Preview
        </Button>
        
        <Button
          size="small"
          variant="contained"
          onClick={handleDraftContent}
        >
          Draft
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={handlePublishContent}
        >
          Publish
        </Button>
        
      </Box>
    </>
  );
};

export default TermAndCondition;
