import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { getAllAdmins } from "../../../store/actions/users";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@mui/material";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

import DeleteConfirmationDialog from "../../../component/common/DeleteConfirmationDialog";
import { updateStatus, deleteUser } from "../../../store/actions/users";
import { useNavigate } from "react-router-dom";
import { URL } from "../../../constants/URL";
import filter from "../../../store/reducers/filter";
import { setFilteredQueryParams } from "../../../store/actions/filter";
import { formatDate } from "../../../component/common/DateFormatter";

const CustomDataGrid = ({ rows, columns, ...props }) => {
  return (
    <DataGrid
      sx={{ height: 'calc(100vh - 172px)' }}
      rows={rows}
      columns={columns}
      {...props}
    />
  );
};

const ServerSideTable = ({ searchQuery }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [data, setData] = useState([]);
  const [sortModel, setSortModel] = useState([
    { field: "updatedAt", sort: "desc" },
  ]);

  const { USER_EDIT } = URL;
  const users = useSelector((state) => state.users.list);
  const { params: filterParams } = useSelector((state) => state.filter);

  const fetchData = async () => {
    try {
      setLoading(true);
      let payload = {
        ...filterParams,
        search: searchQuery,
        page: page ? page : 1,
        perPage: pageSize,
        orderBy: sortModel?.[0]?.field,
        orderAt: sortModel?.[0]?.sort,
      };
      dispatch(setFilteredQueryParams(payload));
      dispatch(getAllAdmins(payload));
    } catch (error) {
      setRows([]); // Reset rows on error
      setRowCount(0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, pageSize, sortModel, searchQuery]);

  useEffect(() => {
    setRows(users?.result || []); // Ensure rows is always an array
    setRowCount(users?.meta?.total || 0); // Ensure totalCount is always a number
    setData(users?.result);
  }, [users]);

  const getPermissionsLabel = (permissions) => {
    let data = permissions.map((m) => m.permission).join(", ");
    if (data.length > 40) {
      return data.slice(0, 40) + "...";
    }
    return data || "N/A";
  };

  const getUpdatedDate = (item) => {
    return item.updatedAt
      ? `${formatDate(item.updatedAt).date} | ${
          formatDate(item.updatedAt).time
        }`
      : `${formatDate(item.createdAt).date} | ${
          formatDate(item.createdAt).time
        }`;
  };

  const columns = [
    {
      field: "firstname",
      headerName: "User",
      width: 150,
      renderCell: (params) => {
        return params.row.firstname + " " + params.row.lastname;
      },
    },
    {
      field: "permissions",
      headerName: "Permissions",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return getPermissionsLabel(params.row.permissions);
      },
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      flex: 1,
      renderCell: (params) => {
        return getUpdatedDate(params.row);
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        params.sortDirection && (
          <span>{params.sortDirection === "asc" ? "▲" : "▼"}</span>
        );
        const HandleActivation = (params) => {
          setLoading(true);
          dispatch(updateStatus(params.row))
            .then((res) => {
              if (!!res.data.isActive) {
                toast.success("Successfully activated!");
              } else {
                toast.success("Successfully deactivated!");
              }
            })
            .catch(() => {
              toast.error("Failed to activate/deactivate user");
            })
            .finally(() => {
              setLoading(false);
              fetchData();
            });
        };

        let activation;
        if (!!params.row.isActive) {
          activation = (
            <Tooltip title="deactivate" position="top">
              <VisibilityOutlinedIcon
    
                onClick={() => HandleActivation(params)}
              />
            </Tooltip>
          );
        } else {
          activation = (
            <Tooltip title="activate">
              <VisibilityOffOutlinedIcon
                onClick={() => HandleActivation(params)}
              />
            </Tooltip>
          );
        }

        const handleDelete = (params) => {
          setLoading(true);
          dispatch(deleteUser(params.row))
            .then(() => {
              toast.success("Successfully deleted!");
              // Refresh the list
              fetchData();
            })
            .catch((error) => {
              setLoading(false);
              toast.error("Failed to delete resource!");
            });
        };

        return (
          <>
            <Tooltip title="Edit">
              <FileOpenOutlinedIcon
                onClick={() => {
                  navigate(USER_EDIT.replace(":id", params.id));
                }}
              />
            </Tooltip>
            {activation}
            <Tooltip title="Delete">
              <DeleteOutlineOutlinedIcon
                onClick={() => setOpen(true)}
              />
            </Tooltip>
            <DeleteConfirmationDialog
              open={open}
              onClose={() => setOpen(false)}
              onDelete={() => handleDelete(params)}
            />
          </>
        );
      },
    },
  ];

  return (
    <CustomDataGrid
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      rowsPerPageOptions={[20, 50, 100]}
      paginationMode="server"
      // sortingMode="server"
      rowCount={rowCount}
      disableColumnMenu
      loading={loading}
      onPageChange={(newPage) => {
        let filters = { ...filterParams, page: newPage + 1, perPage: pageSize };
        dispatch(setFilteredQueryParams(filters));
        setPage(newPage + 1);
      }}
      onPageSizeChange={(newPageSize) => {
        let filters = { ...filterParams, perPage: newPageSize };
        dispatch(setFilteredQueryParams(filters));
        setPageSize(newPageSize);
      }}
      onSortModelChange={(newSortModel) => {
        setSortModel(newSortModel);
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: "updatedAt", sort: "desc" }],
        },
      }}
    />
  );
};

export default ServerSideTable;
