import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { clearCurrentProduct } from "../../../store/actions/currentProduct";
import { getProducts } from "../../../store/actions/product";
import { clearCurrentEvent } from "../../../store/actions/currentEvent";
import { clearCurrentRescource } from "../../../store/actions/currentArticle";
import ContentHeader from "../../../component/common/ContentHeader";
import ProductTable from "../../../component/product/Listing/ProductTable";
import { fetchFAQs, resetFormData } from "../../../store/actions/faqs";
import Table from "./table";
import ServerSideTable from "./ServerTable";
import UserFilter from "../../../component/Filter/User/FilterBox";

const UserList = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const listData = useSelector((state) => state.users.list);
  const [searchQuery, setSearchQuery] = useState("");
  const { params: filterParams } = useSelector((state) => state.filter);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id === undefined) {
      //dispatch(clearCurrentProduct());
      //dispatch(clearCurrentEvent());
      //dispatch(clearCurrentRescource());
    }
    //dispatch(fetchFAQs(filterParams));
  }, [dispatch, id]);

  const handleClearSearchQuery = (event) => {
    setSearchQuery("");
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = listData?.faq?.filter((item) => {
    const enTitle = item?.title?.EN ? item?.title?.EN?.toLowerCase() : null;
    const lnTitle = item?.title?.LU ? item?.title?.LU?.toLowerCase() : null;
    const ryTitle = item?.title?.RY ? item?.title?.RY?.toLowerCase() : null;
    const searchQueryLowerCase = searchQuery?.toLowerCase();

    return (
      (enTitle && enTitle.includes(searchQueryLowerCase)) ||
      (lnTitle && lnTitle.includes(searchQueryLowerCase)) ||
      (ryTitle && ryTitle.includes(searchQueryLowerCase))
    );
  });

  return (
    <>
      <ContentHeader
        header="Users"
        count={listData?.meta?.total || 0}
        route="/users/add"
        searchQuery={searchQuery}
        onSearchQueryChange={handleSearchQueryChange}
        onClearSearchQuery={handleClearSearchQuery}
        searchPlaceholder="Search by User"
        filterBox={UserFilter}
      />
      <ServerSideTable searchQuery={searchQuery} />
    </>
  );
};

export default UserList;
