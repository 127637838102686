import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setVideoUrl } from "../../store/actions/currentArticle";
import { localizedUrlSelector } from "../../store/reducers/selector";
import TextFields from "./TextFields";
import { Box } from "@mui/material";

const VideoUrlField = () => {
  const dispatch = useDispatch();

  const url = useSelector(localizedUrlSelector);
  const lang = useSelector((state) => state.article.language);
  const selectedVideo = useSelector((state) => state.article.video[lang]);

  const HandleVideoUrl = (event) => {
    const text = event.target.value;
    const trimmedText = text.trimStart(); // Remove only leading spaces

    dispatch(setVideoUrl(trimmedText));
  };

  return (
    <Box>
      <TextFields
        disabled={selectedVideo.length > 0}
        error={selectedVideo.length > 0 && "Video is already uploaded"}
        placeholder={"eg. www.TickAcademy.com/video1911"}
        defaultValue={url || ""}
        name={"Copy and paste the video URL here:"}
        handleTextField={HandleVideoUrl}
        title={"Copy and paste the video URL here:"}
        helperText={
          selectedVideo.length > 0
            ? "Video is already uploaded"
            : "Only Youtube Links are supported *"
        }
      ></TextFields>
    </Box>
  );
};

export default VideoUrlField;
