// DeleteConfirmationDialog.js

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

export default function ConfirmationDialog({
  open,
  title,
  content,
  onClose,
  onCancel,
  onDelete,
  hideCancel = false,
  hideDelete = false,
}) {
  const handleDelete = () => {
    onDelete();
    onClose();
  };

  return (
    <Dialog open={Boolean(open)} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        {hideCancel && <Button onClick={onCancel}>Cancel</Button>}
        {hideDelete && (
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
