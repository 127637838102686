import axios from "axios";
import { SESSION_STORAGE } from "../constants/GeneralConstant";

// Default base URL
const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

// Create Axios instance
const createInstance = (version = "v1") => {
  return axios.create({
    baseURL: `${BASE_URL}/${version}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// Attach interceptors for Authorization
const attachInterceptors = (instance) => {
  instance.interceptors.request.use(
    (config) => {
      const user = sessionStorage.getItem("user");
      if (user) {
        const token = JSON.parse(user).token;
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      const speciesLanguage = sessionStorage.getItem(
        SESSION_STORAGE.SPEC_LANGUAGE
      );
      if (speciesLanguage != null) {
        config.headers["Accept-Language"] = speciesLanguage;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
};

// API Service
const apiService = {
  /**
   * Make a POST request
   * @param {string} endpoint - API endpoint
   * @param {object} data - Request body
   * @param {string} [version="v1"] - API version
   * @returns {Promise<any>} - API response
   */
  post: async (endpoint, data, version = "v1") => {
    const instance = createInstance(version);
    attachInterceptors(instance);
    return instance.post(endpoint, data);
  },

  /**
   * Make a GET request
   * @param {string} endpoint - API endpoint
   * @param {object} [params={}] - Query parameters
   * @param {string} [version="v1"] - API version
   * @returns {Promise<any>} - API response
   */
  get: async (endpoint, params = {}, version = "v1") => {
    const instance = createInstance(version);
    attachInterceptors(instance);
    return instance.get(endpoint, { params });
  },

  /**
   * Make a PATCH request
   * @param {string} endpoint - API endpoint
   * @param {object} data - Request body
   * @param {string} [version="v1"] - API version
   * @returns {Promise<any>} - API response
   */
  patch: async (endpoint, data, version = "v1") => {
    const instance = createInstance(version);
    attachInterceptors(instance);
    return instance.patch(endpoint, data);
  },

  /**
   * Make other requests (PUT, DELETE, etc.)
   * @param {string} method - HTTP method (PUT, DELETE, etc.)
   * @param {string} endpoint - API endpoint
   * @param {object} data - Request body
   * @param {string} [version="v1"] - API version
   * @returns {Promise<any>} - API response
   */
  request: async (method, endpoint, data, version = "v1") => {
    const instance = createInstance(version);
    attachInterceptors(instance);
    return instance.request({
      method,
      url: endpoint,
      ...data,
    });
  },

  /**
   * Make a DELETE request
   * @param {string} endpoint - API endpoint
   * @param {object} [params={}] - Query parameters (optional)
   * @param {string} [version="v1"] - API version
   * @returns {Promise<any>} - API response
   */
  delete: async (endpoint, params = {}, version = "v1") => {
    const instance = createInstance(version);
    attachInterceptors(instance);
    return instance.delete(endpoint, { params });
  },

  /* Make a PATCH request
   * @param {string} endpoint - API endpoint
   * @param {object} data - Request body
   * @param {string} [version="v1"] - API version
   * @returns {Promise<any>} - API response
   */
  patch: async (endpoint, data, version = "v1") => {
    const instance = createInstance(version);
    attachInterceptors(instance);
    return instance.patch(endpoint, data);
  },
};

export default apiService;
