/* eslint-disable react-hooks/rules-of-hooks */
import {
  GET_LISTING_SUCCESS,
  GET_LISTING_FAIL,
  DELETE_RECORD_SUCCESS,
  DELETE_RECORD_FAIL,
  TOGGLE_GATING_CONTENT_FAILURE,
  TOGGLE_GATING_CONTENT_SUCCESS,
  TOGGLE_ACTIVATION_CONTENT_SUCCESS,
  DELETE_LEARING_RESOURCE_SUCCESS,
  DELETE_LEARING_RESOURCE_FAILURE,
  TOGGLE_ACTIVATION_CONTENT_FAILURE,
  CREATE_LEARNING_RESOURCE_FAIL,
  CREATE_LEARNING_RESOURCE_SUCCESS,
  EDIT_LEARNING_RESOURCE_SUCCESS,
  EDIT_LEARNING_RESOURCE_FAIL,
  TOGGLE_MARK_PREMIUM_SUCCESS,
  TOGGLE_MARK_PREMIUM_FAILURE,
  GET_RECOMMENDATION_SUCCESS,
} from "./type";
import api from "../../services/api";
import { trackPromise } from "react-promise-tracker";
import { CONTENT_STATES } from "../../constants/GeneralConstant";

export const ListingRecord = (url, param) => async (dispatch) => {
  try {
    const res = await trackPromise(api.post(url, param));

    dispatch({
      type: GET_LISTING_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({ type: GET_LISTING_FAIL, payload: err.message });
  }
};

export const retrieveLearningRecommendation = () => async (dispatch) => {
  try {
    const res = await trackPromise(api.post("/learning-resource/list", {}));
    dispatch({
      type: GET_RECOMMENDATION_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({ type: GET_LISTING_FAIL, payload: err.message });
  }
  // Add the spinner component based on the promise status
};

export const retrieveLearningResource = (queryparams) => async (dispatch) => {
  if (Object.keys(queryparams).length === 0) {
    dispatch({ type: GET_LISTING_FAIL, payload: err.message });
  } else {
    try {
      const res = await trackPromise(
        api.post("/learning-resource/list", queryparams)
      );

      dispatch({
        type: GET_LISTING_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({ type: GET_LISTING_FAIL, payload: err.message });
    }
  }
  // Add the spinner component based on the promise status
};

export const viewRecord = (url, param) => async (dispatch) => {
  try {
    const res = await trackPromise(api.get(url, param));

    dispatch({
      type: GET_LISTING_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({ type: GET_LISTING_FAIL, payload: err.message });
  }
};

export const createResource = (url, resource) => {
  const { language, vBodyPart, ...resourceData } = resource;

  return async (dispatch) => {
    try {
      const res = await trackPromise(api.post(url, resourceData));
      dispatch({
        type: CREATE_LEARNING_RESOURCE_SUCCESS,
        payload: res.data.data,
      });

      return Promise.resolve();
    } catch (err) {
      console.log("error:", err);
      dispatch({
        type: CREATE_LEARNING_RESOURCE_FAIL,
        payload: err.message,
      });
      return Promise.reject();
    }
  };
};

export const editResource = (url, id, resource) => {
  const { language, vBodyPart, ...resourceContent } = resource;
  return async (dispatch) => {
    try {
      const res = await trackPromise(
        api.patch(`${url}/${id}`, resourceContent)
      );
      dispatch({
        type: EDIT_LEARNING_RESOURCE_SUCCESS,
        payload: res.data.data,
      });
      return Promise.resolve();
    } catch (err) {
      dispatch({
        type: EDIT_LEARNING_RESOURCE_FAIL,
        payload: err.message,
      });
      return Promise.reject();
    }
  };
};

export const deleteRecord = (id) => async (dispatch) => {
  try {
    const res = await trackPromise(api.delete(`/learning-resource/${id}`));
    dispatch({
      type: DELETE_RECORD_SUCCESS,
      payload: res.data,
    });
    return Promise.resolve();
  } catch (err) {
    dispatch({ type: DELETE_RECORD_FAIL, payload: err.message });
  }
  return Promise.reject();
};

export const toggleContentGating = (url, content) => async (dispatch) => {
  try {
    const res = await trackPromise(
      api.patch(`${url}/${content.id}`, {
        isAuthRequired: !content.isAuthRequired,
      })
    );

    dispatch({
      type: TOGGLE_GATING_CONTENT_SUCCESS,
      payload: res.data.data,
    });
    return Promise.resolve();
  } catch (err) {
    dispatch({ type: TOGGLE_GATING_CONTENT_FAILURE, payload: err.message });
  }
  return Promise.reject();
};

export const toggleContentActivation =
  (id = null, resource) =>
  async (dispatch) => {
    const { language, ...content } = resource;
    const temp =
      content.status === CONTENT_STATES.PUBLISHED
        ? CONTENT_STATES.ARCHIVED
        : CONTENT_STATES.PUBLISHED &&
          content.status !== CONTENT_STATES.PUBLISHED
        ? CONTENT_STATES.PUBLISHED
        : null;
    try {
      const res = await trackPromise(
        api.patch(`/learning-resource/${id}`, {
          status: temp,
        })
      );
      dispatch({
        type: TOGGLE_ACTIVATION_CONTENT_SUCCESS,
        payload: res.data.data,
      });
      return Promise.resolve();
    } catch (err) {
      dispatch({
        type: TOGGLE_ACTIVATION_CONTENT_FAILURE,
        payload: err.message,
      });
      return Promise.reject();
    }
  };

export const toggleTickActivation =
  (id = null, resource) =>
  async (dispatch) => {
    const { language, ...content } = resource;
    const temp =
      content.status === CONTENT_STATES.PUBLISHED
        ? CONTENT_STATES.DRAFT
        : CONTENT_STATES.PUBLISHED &&
          content.status !== CONTENT_STATES.PUBLISHED
        ? CONTENT_STATES.PUBLISHED
        : null;
    try {
      const res = await trackPromise(
        api.patch(`/learning-resource/${id}`, {
          status: temp,
        })
      );
      dispatch({
        type: TOGGLE_ACTIVATION_CONTENT_SUCCESS,
        payload: res.data.data,
      });
      return Promise.resolve();
    } catch (err) {
      dispatch({
        type: TOGGLE_ACTIVATION_CONTENT_FAILURE,
        payload: err.message,
      });
      return Promise.reject();
    }
  };

export const toggleContentMarkPremium =
  (id = null, resource) =>
  async (dispatch) => {
    const { language, isPremium, ...content } = resource;

    try {
      const res = await trackPromise(
        api.patch(
          `/learning-resource/mark-premium/${id}/${!isPremium}`,
          {},
          "v2"
        )
      );
      dispatch({
        type: TOGGLE_MARK_PREMIUM_SUCCESS,
        payload: res.data.data,
      });

      return Promise.resolve();
    } catch (err) {
      dispatch({
        type: TOGGLE_MARK_PREMIUM_FAILURE,
        payload: err.message,
      });
      return Promise.reject();
    }
  };

export const deleteLearningResource = (id) => async (dispatch) => {
  try {
    const res = await trackPromise(api.delete(`/learning-resource/${id}`));
    dispatch({
      type: DELETE_LEARING_RESOURCE_SUCCESS,
      payload: res.data,
    });
    return Promise.resolve();
  } catch (err) {
    dispatch({ type: DELETE_LEARING_RESOURCE_FAILURE, payload: err.message });
  }
  return Promise.reject();
};
