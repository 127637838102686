// src/redux/thunks/faqThunks.js

import {
  SET_FAQS_LANGUAGE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  UPDATE_STATUS_USER_REQUEST,
  UPDATE_STATUS_USER_SUCCESS,
  UPDATE_STATUS_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  UPDATE_USER_FORM,
  FETCH_USER_PERMISSIONS_REQUEST,
  FETCH_USER_PERMISSIONS_SUCCESS,
  FETCH_USER_PERMISSIONS_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  FETCH_USER_BY_ID_REQUEST,
  FETCH_USER_BY_ID_SUCCESS,
  FETCH_USER_BY_ID_FAILURE,
  RESET_ADMIN_USER_FORM,
  SET_SELECTED_PERMISSIONS,
  USER_SETUP_PASSWORD_SUCCESS,
  USER_SETUP_PASSWORD_REQUEST,
  USER_SETUP_PASSWORD_FAILURE,
  USER_RESEND_PASSWORD_REQUEST,
  USER_RESEND_PASSWORD_SUCCESS,
  USER_RESEND_PASSWORD_FAILURE,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_FAILURE,
  RESEND_OTP_REQUEST,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_FAILURE,
} from "./type";
import {
  ADMIN_URL,
  ADMIN_STATUS_UPDATE,
  ADMIN_DELETE,
  GET_ALL_PERMISSIONS,
  GET_ADMIN_BY_ID,
  ADMIN,
  ADMIN_SETUP_PASSWORD,
  ADMIN_RESEND_PASSWORD,
  ADMIN_RESEND_PASSWORD_EMAIL,
  VERIFY_OTP,
  RESEND_OTP,
} from "../../constants/apiUrls";
import api from "../../services/api";
import { trackPromise } from "react-promise-tracker";

const API_VERSION_V1 = "v1";
const API_VERSION = "v2";

export const updateFormData = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_FORM,
    payload: data,
  });
};

export const createAdmin = (params) => async (dispatch) => {
  dispatch({ type: CREATE_USER_REQUEST });
  try {
    const response = await trackPromise(api.post(ADMIN, params, API_VERSION));
    dispatch({
      type: CREATE_USER_SUCCESS,
      payload: response.data.data,
    });
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch({
      type: CREATE_USER_FAILURE,
      payload: error.message,
    });
    return Promise.reject(error);
  }
};

// Update Admin
export const updateAdmin = (id, params) => async (dispatch) => {
  dispatch({ type: UPDATE_STATUS_USER_REQUEST });
  try {
    const response = await trackPromise(
      api.patch(`${ADMIN}/${id}`, params, API_VERSION)
    );
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: response.data.data,
    });
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAILURE,
      payload: error.message,
    });
    return Promise.reject(error);
  }
};

export const resetFormData = () => async (dispatch) => {
  dispatch({
    type: RESET_ADMIN_USER_FORM,
    payload: {},
  });
};

export const updatePermissionIds = (data) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_PERMISSIONS,
    payload: data,
  });
};

// Get Admin by ID
export const getAdminById = (id) => async (dispatch) => {
  dispatch({ type: FETCH_USER_BY_ID_REQUEST });
  try {
    const response = await trackPromise(
      api.get(`${GET_ADMIN_BY_ID.replace(":id", id)}`, {}, API_VERSION)
    );
    dispatch({
      type: FETCH_USER_BY_ID_SUCCESS,
      payload: response.data.data,
    });
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch({
      type: FETCH_USER_BY_ID_FAILURE,
      payload: error.message,
    });
    return Promise.reject(error);
  }
};

export const getAllAdmins = (params) => async (dispatch) => {
  dispatch({
    type: FETCH_USER_REQUEST,
  });
  try {
    const response = await trackPromise(
      api.post(ADMIN_URL, params, API_VERSION)
    );
    dispatch({
      type: FETCH_USER_SUCCESS,
      payload: response.data.data,
    });
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch({
      type: FETCH_USER_FAILURE,
      payload: error.message,
    });
    return Promise.reject(error);
  }
};

export const getAllPermissions = (params) => async (dispatch) => {
  dispatch({
    type: FETCH_USER_PERMISSIONS_REQUEST,
  });
  try {
    const response = await trackPromise(
      api.post(GET_ALL_PERMISSIONS, params, API_VERSION_V1)
    );
    dispatch({
      type: FETCH_USER_PERMISSIONS_SUCCESS,
      payload: response.data.data,
    });
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch({
      type: FETCH_USER_PERMISSIONS_FAILURE,
      payload: error.message,
    });
    return Promise.reject(error);
  }
};

export const updateStatus = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_STATUS_USER_REQUEST,
  });
  try {
    const { id, isActive } = data || {};
    const response = await trackPromise(
      api.patch(
        ADMIN_STATUS_UPDATE.replace(":id", id).replace(":status", !isActive),
        {},
        API_VERSION
      )
    );
    dispatch({
      type: UPDATE_STATUS_USER_SUCCESS,
      payload: response.data.data,
    });
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch({
      type: UPDATE_STATUS_USER_FAILURE,
      payload: error.message,
    });
    return Promise.reject(error);
  }
};

export const deleteUser = (data) => async (dispatch) => {
  dispatch({
    type: DELETE_USER_REQUEST,
  });
  try {
    const { id } = data || {};
    const response = await trackPromise(
      api.delete(ADMIN_DELETE.replace(":id", id), {}, API_VERSION)
    );
    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: response.data.data,
    });
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch({
      type: DELETE_USER_FAILURE,
      payload: error.message,
    });
    return Promise.reject(error);
  }
};

export const setupPassword = (data) => async (dispatch) => {
  dispatch({
    type: USER_SETUP_PASSWORD_REQUEST,
  });
  try {
    const response = await trackPromise(
      api.patch(ADMIN_SETUP_PASSWORD, data, API_VERSION)
    );
    dispatch({
      type: USER_SETUP_PASSWORD_SUCCESS,
      payload: response.data.data,
    });
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch({
      type: USER_SETUP_PASSWORD_FAILURE,
      payload: error.message,
    });
    return Promise.reject(error);
  }
};

export const resendToken = (data) => async (dispatch) => {
  dispatch({
    type: USER_RESEND_PASSWORD_REQUEST,
  });
  try {
    const response = await trackPromise(
      api.patch(ADMIN_RESEND_PASSWORD_EMAIL, data, API_VERSION)
    );
    dispatch({
      type: USER_RESEND_PASSWORD_SUCCESS,
      payload: response.data.data,
    });
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch({
      type: USER_RESEND_PASSWORD_FAILURE,
      payload: error.message,
    });
    return Promise.reject(error);
  }
};

export const verifyOTP = (data) => async (dispatch) => {
  dispatch({
    type: VERIFY_OTP_REQUEST,
  });
  try {
    const response = await trackPromise(
      api.post(VERIFY_OTP, data, API_VERSION)
    );
    dispatch({
      type: VERIFY_OTP_SUCCESS,
      payload: response.data.data,
    });
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch({
      type: VERIFY_OTP_FAILURE,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const resendOTPToken = (data) => async (dispatch) => {
  dispatch({
    type: RESEND_OTP_REQUEST,
  });
  try {
    const response = await trackPromise(
      api.post(RESEND_OTP, data, API_VERSION)
    );
    dispatch({
      type: RESEND_OTP_SUCCESS,
      payload: response.data.data,
    });
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch({
      type: RESEND_OTP_FAILURE,
      payload: error.message,
    });
    return Promise.reject(error);
  }
};
