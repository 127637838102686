import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSpecies,
  deleteSpecies,
  getSpecies,
} from "../../../../store/actions/species";
import CheckBoxListInput from "../../../formFields/CheckBoxListInput";
import { WithHeader } from "../../../common/WithHeader";
import { languageSelector } from "../../../../store/reducers/selector";
import {
  getContentSpecies,
  setSpecies,
} from "../../../../store/actions/nearByActions";

const SpeciesManager = () => {
  const dispatch = useDispatch();

  const language = useSelector((state) => state.nearBy.language);
  const speciesList = useSelector((state) => state.nearBy.contentSpecies);
  const selectedSpecies = useSelector((state) => state.nearBy.species);

  useEffect(() => {
    dispatch(getContentSpecies(language));
  }, [language]);

  const handleAdd = (title) =>
    dispatch(createSpecies(title, language)).then(() => dispatch(getSpecies()));

  const handleDelete = (id) => dispatch(deleteSpecies(id));

  const handleToggle = (id) => {
    const localizedSpecies = selectedSpecies?.[language]?.split(",") ?? []; // Split into an array if it's a string

    // Toggle logic: Add if not present, remove if present
    const updatedSpecies = localizedSpecies.includes(id)
      ? localizedSpecies.filter((species) => species !== id) // Remove the id
      : [...localizedSpecies, id]; // Add the id

    const payload = updatedSpecies.join(","); // Convert back to a comma-separated string

    dispatch(setSpecies(payload)); // Dispatch the updated string
  };

  return (
    <CheckBoxListInput
      items={speciesList ?? []}
      selectedItems={selectedSpecies?.[language]?.split(",") ?? []}
      onAddItem={handleAdd}
      onDeleteItem={handleDelete}
      onToggleItem={handleToggle}
      getItemTitle={(item) => item.name}
      maxSelectionLimit={5}
      allowAddItem={false}
    />
  );
};

export default WithHeader(SpeciesManager, "Species *");
