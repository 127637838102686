// src/redux/thunks/faqThunks.js

import {
  ADD_FAQS_CATEGORY_SUCCESS,
  DELETE_FAQS_CATEGORY_SUCCESS,
  FETCH_FAQS_CATEGORIES_FAILURE,
  FETCH_FAQS_CATEGORIES_REQUEST,
  FETCH_FAQS_CATEGORIES_SUCCESS,
  SET_FAQS_CATEGORIES_LANGUAGE,
  UPDATE_FAQS_CATEGORIES_FORM,
} from "./type";
import { FAQ_CATEGORIES_URL } from "../../constants/apiUrls";
import api from "../../services/api";
import { trackPromise } from "react-promise-tracker";

export const updateFaqCategoriesData = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_FAQS_CATEGORIES_FORM,
    payload: data,
  });
};

export const setFaqCategoryLanguage = (lang) => async (dispatch) => {
  dispatch({
    type: SET_FAQS_CATEGORIES_LANGUAGE,
    payload: lang,
  });
};

export const fetchFAQCategories = () => async (dispatch) => {
  dispatch({
    type: FETCH_FAQS_CATEGORIES_REQUEST,
  });
  try {
    const response = await trackPromise(api.get(FAQ_CATEGORIES_URL, {}, "v2"));
    dispatch({
      type: FETCH_FAQS_CATEGORIES_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_FAQS_CATEGORIES_FAILURE,
      payload: error.message,
    });
  }
};

export const addFAQCategory = (cat) => async (dispatch) => {
  try {
    const response = await trackPromise(
      api.post(FAQ_CATEGORIES_URL, cat, "v2")
    );
    dispatch({
      type: ADD_FAQS_CATEGORY_SUCCESS,
      payload: response.data,
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteFAQCategory = (id) => async (dispatch) => {
  try {
    const response = await trackPromise(
      api.delete(`${FAQ_CATEGORIES_URL}/${id}`, {}, "v2")
    );
    dispatch({
      type: DELETE_FAQS_CATEGORY_SUCCESS,
      payload: response.data,
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
