import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  FORGET_PASSWORD_FAIL,
  FORGET_PASSWORD_SUCCESS,
  SECURITY_CODE_SUCCESS,
  SECURITY_CODE_FAIL,
  PERMISSIONS_SUCCESS,
  PERMISSIONS_FAIL,
} from '../actions/type';

const user = JSON.parse(sessionStorage.getItem('user'));

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

export default function auth(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case FORGET_PASSWORD_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case SECURITY_CODE_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case SECURITY_CODE_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };

    case PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: payload,
      };
    case PERMISSIONS_FAIL:
      return {
        ...state,
        permissions: null,
      };
    // case REFRESH_TOKEN:
    //   return {
    //     ...state,
    //     user: { ...user, accessToken: payload },
    //   };
    default:
      return state;
  }
}
