import { SET_DRAWER_CLOSE, SET_DRAWER_OPEN } from "../actions/type";

const initialState = {
  drawerOpen: false,
};

function toolbar(state = initialState, action) {
  switch (action.type) {
    case SET_DRAWER_OPEN:
      return {
        ...state,
        drawerOpen: action.payload,
      };
    case SET_DRAWER_CLOSE:
      return {
        ...state,
        drawerOpen: action.payload,
      };
    default:
      return state;
  }
}

export default toolbar;
