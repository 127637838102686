import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  Button,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
  Box
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import FilterBox from "../Filter/FilterBox";
import HandleGateCheckBox from "../NearBy/NearByList/GateUnGateCheckBox";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import { Tooltip } from "@mui/material";
import { set } from "date-fns";

const ContentHeader = (props) => {
  const navigate = useNavigate();
  const [filterBoxOpen, setFilterBoxOpen] = useState(false);
  const [search, setSerach] = useState(props.searchQuery || "");

  // check for pass filter box
  const FilterBoxPassed = props.filterBox;

  useEffect(() => {
    const handler = setTimeout(() => {
      props.onSearchQueryChange({  target: { value: search } });
  }, 700);

    // Cleanup timeout if input changes before delay is over
    return () => clearTimeout(handler);
  }, [search]);

  const handleFilterBoxOpen = () => {
    setFilterBoxOpen(true);
  };

  const handleFilterBoxClose = () => {
    setFilterBoxOpen(false);
  };

  return (
    <Box
      sx={{
          display: "flex",
          mb: 1.5,
          pt: 0.75,
          pb: 2,
          borderBottom: '1px solid #E1E1E1',
        }}
      >

      <Typography variant="h5">
        {props.header}
        { !!props.count && <sup style={{ fontSize: '0.6em', marginLeft: '4px' }}>({props.count})</sup> }
      </Typography>
   

      <Box
        sx={{
          display: "flex",
          marginLeft: "auto",
          justifyContent: "space-between",
          gap: 1 , 
        }}
      >

        {/* <HandleGateCheckBox></HandleGateCheckBox> */}
      
        <TextField
          placeholder={props.searchPlaceholder}
          className="searchBar"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon color="action" fontSize="small">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
            endAdornment: props.searchQuery ? (
              <InputAdornment position="end">
                <IconButton onClick={() => { props.onClearSearchQuery(); setSerach("") }}>
                  <ClearIcon color="action" fontSize="small" />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
          variant="outlined"
          size="small"
          value={search}
          onChange={(e) => setSerach(e?.target?.value)}
        />
        {!props.isNearBy && (
          <FilterAltOutlinedIcon
            sx={{
              cursor: "pointer",
              fontSize: "40px",
              border: "1px solid #E1E1E1",
              padding: "5px",
              borderRadius: "8px",
              color: "#rgb(51 51 51 / 73%)",
              transition: "all 0.3s ease",
              '&:hover': {
                backgroundColor: '#118197',
                color: '#fff', 
              },
              '&.selected-filter': {
                backgroundColor: '#118197', // Applied when class is active
                color: '#fff',
              },
            }}
            onClick={handleFilterBoxOpen}
          />
        )}

        {!!FilterBoxPassed ? (
          <FilterBoxPassed
            open={filterBoxOpen}
            handleClose={handleFilterBoxClose}
          />
        ) : (
          <FilterBox
            type={props.type}
            open={filterBoxOpen}
            handleClose={handleFilterBoxClose}
          />
        )
        }

        {props.isNearBy && (
          <Box 
            sx={{ 
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Tooltip title="Gate All">
              <LockPersonOutlinedIcon
                onClick={() => props.HandleGateToggleAll(false)}
                sx={{cursor: 'pointer'}}
              />
            </Tooltip>

            <Tooltip title="Ungate All">
              <LockOpenOutlinedIcon
                onClick={() => props.HandleGateToggleAll(true)}
                sx={{cursor: 'pointer'}}
              />
            </Tooltip>
          </Box>
        )}
        

        <Button
          variant="contained"
          size="small"
          onClick={() => navigate(props.route)}
        >
          + Add New
        </Button>
        {props.isNearBy && (
          <Button
            variant="contained"
            size="small"
            onClick={() => navigate(props.bulkImportRoute)}
          >
            Bulk Import
          </Button>
        )}
        
      </Box>

    </Box>
  );
};

export default ContentHeader;
