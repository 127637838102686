// const getLocalRefreshToken = () => {
//   const user = JSON.parse(sessionStorage.getItem('user'));
//   return user?.refreshToken;
// };

const getLocalAccessToken = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  return user?.accessToken;
};

const updateLocalAccessToken = (token) => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  user.accessToken = token;
  sessionStorage.setItem('user', JSON.stringify(user));
};

const getUser = () => JSON.parse(sessionStorage.getItem('user'));

const setUser = (user) => {
  // localStorage.setItem('user', JSON.stringify(user));
  sessionStorage.setItem('user', JSON.stringify(user));
};

const getPermissions = () =>
  JSON.parse(sessionStorage.getItem('permissions_elanco'));

const setPermissions = (data) => {
  sessionStorage.setItem('permissions_elanco', JSON.stringify(data));
};

const removeUser = () => {
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('permissions_elanco');
};

const TokenService = {
  // getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  removeUser,
  getPermissions,
  setPermissions
};

export default TokenService;
