import { trackPromise } from "react-promise-tracker";
import api from "../../services/api";
import { GET_ADMIN_DATA_FAIL, GET_ADMIN_DATA_SUCCESS } from "./type";

export const getAdminData = () => async (dispatch) => {
  try {
    const res = await trackPromise(api.get("/profile/users?isAdmin=true"));

    dispatch({
      type: GET_ADMIN_DATA_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({ type: GET_ADMIN_DATA_FAIL, payload: err.message });
  }
};
