import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

export const WithHeader = (WrappedComponent, headerLabel) => {
  return function WihHeaderComponent({ ...props }) {
    return (
      <Box
        border={1}
        borderRadius="0.2em 0.2em 0 0"
        borderColor="#d9d9d9"
      >

        <Typography variant="h6" 
          sx={{ 
            backgroundColor: '#f1f1f1', 
            p: 1.5, fontSize: '16px', 
            borderBottom: '1px solid #d9d9d9', 
          }}>
          {props?.title
            ? props?.title.split("*").map((text, index, array) => (
                <React.Fragment key={index}>
                  {text}
                  {index !== array.length - 1 && (
                    <span style={{ color: "#E23939" }}>*</span>
                  )}
                </React.Fragment>
              ))
            : headerLabel?.split("*").map((text, index, array) => (
                <React.Fragment key={index}>
                  {text}
                  {index !== array.length - 1 && (
                    <span style={{ color: "#E23939" }}>*</span>
                  )}
                </React.Fragment>
              ))}
        </Typography>
        
        <WrappedComponent {...props} />
      </Box>
    );
  };
};
